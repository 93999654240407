import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';


import {GET_ADDLOANCONFIG }  from '../../actions'; 

class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			label:'',			
			amount:'',
			percentage:'',
			amount_type:'',
			selectedOption:{ value: 'merchant', label: 'Merchant'},
			selectedvalue : 'merchant',
			Loading:false
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
	

    handleChange = selectedOption => {
		console.log(selectedOption, 'sel_opt');
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Loan Configuration Add');
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				const amount = formPayload.amount.replace('$', '');

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					label: formPayload.label,					
					amount:amount,
					percentage:formPayload.percentage,
					amount_type:formPayload.selectedvalue,
				};
				console.log(postObject, 'post_obj');
				//this.props.getAddCompany(qs.stringify(postObject));
				
				axios.post(apiUrl+"loanconfig/add",qs.stringify(postObject)).then(res => {
					this.setState({Loading:false});
					if(res.data.status === "success"){		
					
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({
							label:'',			
							amount:'',
							percentage:'',
							amount_type:'',
						});
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/loanconfiguration'));
						}
						.bind(this),
						3000
						);
					 }else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					 }				 
					 
    	       });	
			}
	}
	handlePriceChange = (e) => {
		const inputValue = e.target.value;
	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			amount: priceWithSymbol
		  });
		}
	  };
	validateForm() {
		const {label,amount,percentage,amount_type} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!label) {
			formIsValid = false;
			$('.errorlabel').html('<span class="errorspan">Please fill the field</span>');
		}else if(label){
			$('.errorlabel').html('');
		}
		
		// if (!amount) {
		// 	formIsValid = false;
		// 	$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		// }else if(amount){
		// 	$('.erroramount').html('');
		// }		
		if (!amount) {
			formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		}else if(amount){
			if (/^\$?[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/.test(amount)) {

				$('.erroramount').html('');
			  }else{
				formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please enter the valid amount</span>');
			  }

			
		}

		// if (!admin_contact) {
		// 	formIsValid = false;
		// 	$('.erroradmin_contact').html('<span class="errorspan">Please fill the field</span>');
		// }else if(admin_contact){
		// 	$('.erroradmin_contact').html('');
		// }

		if (!percentage) {
			formIsValid = false;
			$('.errorpercentage').html('<span class="errorspan">Please fill the field</span>');
		}else if(percentage){
			$('.errorpercentage').html('');
		}



		this.setState({
			errors: errors
		});
		return formIsValid;
    }

	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="loanconfig" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Loan Configuration</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Label<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="label" onChange={this.handleInputChange} className="form-control" value={this.state.label} />
					<div className="errorlabel"></div>
				</div>
				
				<div className="form-group">
					<label>Percentage<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="number" pattern="[0-9]*" name="percentage" onChange={this.handleInputChange} className="form-control" value={this.state.percentage} />
					<div className="errorpercentage"></div>
				</div>
			</div>
			<div className="form-right">	
				
				<div className="form-group">					
					<label>Amount<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="text" name="amount" onChange={this.handlePriceChange} className="form-control" value={this.state.amount} />
					<div className="erroramount"></div>
				</div>

				<div className="form-group">					
					<label>Amount Type</label>
						   <Select name="amount_type"
						   value={selectedOption?selectedOption.selectedvalue:{ value: 'merchant', label: 'Merchant'}}
                            options={lang.common.amounttype_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


/*const mapStateTopProps = (state) => {
  return {
  	companyadd: state.companyadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddCompany: (formPayload) =>{
       dispatch({ type: GET_ADDLOANCONFIG, formPayload});
    }
  }
}*/

export default (withRouter(Add));