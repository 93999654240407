import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,categoryURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import CKEditor from 'ckeditor4-react';


class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const faqId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            faqId: faqId,
			faq_question:'',
			faq_answer:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedCateOption: "",
			selectedvalue:'',
			selectedCatevalue:''
		};
		// this.props.getCategoryDetailList(faqId);
		var qs = require('qs');
		var catid = this.state.faqId;

		axios.get(apiUrl+"faq/listdetail?id="+catid).then(res => {
			this.setState({faq_question:res.data.faqlist.faq_question,
				faq_answer:res.data.faqlist.faq_answer});
			if(res.data.faqlist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}

			var postObject = {
				id: res.data.faqlist.faq_cate_id,			
			};

			axios.post(apiUrl+"faq/catedetail",qs.stringify(postObject)).then(res => {
				if(res.data.status == "success"){					
					let catedetail = res.data.catedetail;
					this.setState({selectedCateOption:{value: catedetail['id'], label: catedetail['cate_name']}});					
				}
			});
			
			
		}); 

		//axios.get(apiUrl+'faq/getspecialistnamelist?admin_id='+localStorage.getItem("admin_id"))
		
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            faq_answer: evt.editor.getData()
        } );
		}, 1000);
     
    }
    // handleChange = selectedOption => {

    //     this.setState({selectedOption})
    //     this.setState({  selectedvalue : selectedOption.value});
    // };

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'ins_cate'){			
			this.setState({selectedCateOption:selOption})
			this.setState({  selectedCatevalue : selOption.value});
		}
 	};
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     		
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}
	
	handleChangeSpecialist = selectedSpecialist => {

		this.setState({ selectedSpecialist});
		this.setState({ specialistvalue : selectedSpecialist.value });
 }


	componentDidMount() {
		document.title = PageTitle('Faq Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status, cate = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedCatevalue === '' && formPayload.selectedCatevalue!== 0){
					cate =formPayload.selectedCateOption.value;
				}else{
					cate = formPayload.selectedCatevalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					faq_question: formPayload.faq_question,
					faq_answer:formPayload.faq_answer,
					status:  status,
					id: formPayload.faqId,
					cate_id:cate
				
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"faq/edit",formData ).then(res => {
					
					if(res.data.status === "success"){	
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
						this.setState({Loading:false});
					}
				});
			}
	}

	validateForm() {
		const {faq_question,faq_answer,selectedCateOption} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		  if(!faq_question){
			formIsValid = false;
			$('.errorfaq_question').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorfaq_question').html('');
		}

		if(!faq_answer){
			formIsValid = false;
			$('.errorfaq_answer').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorfaq_answer').html('');
		}

		if(!selectedCateOption){
			formIsValid = false;
			$('.errorcate').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcate').html('');
		}
			
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
  render() {


	

  	  const {selectedOption,selectedCateOption} = this.state;	


  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Faq" />  
      <div className="content">	
		<div className="content-wrapper">
		<div className="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedCateOption?selectedCateOption:""}
                            options={lang.common.categories_option} 
                            onChange={this.handleChange.bind(this, 'ins_cate')}
                            />
	           </div> 
				<div className="form-group">					
					<label>Answer<span class="required" style={{ color: "red" }} > * </span> :</label>
						<textarea type="text" name="faq_answer" onChange={this.handleInputChange} className="form-control" value={this.state.faq_answer}  />
						{/* <CKEditor
					data={this.state.faq_answer}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					<div className="errorfaq_answer"></div>
				</div>
			
			</div>
                  
			<div className="form-right">
				
				<div className="form-group">
					<label> Question<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="faq_question" onChange={this.handleInputChange} className="form-control" value={this.state.faq_question} />
					<div className="errorfaq_question"></div>
				</div>
				
               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));