import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, categoryURL} from'../Config/Config';
import { PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const categoryid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      categoryid: categoryid,
      categorylistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :categoryid
    };

    axios.post(apiUrl+"category/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ categorylistdata: res.data.categorylistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Category View');
   }
  render() {
  if(this.state.categorylistdata!==undefined){
    var categorylistdata = this.state.categorylistdata;
  }
  let loaderimg = '';
  let preview = '';

  var imagepre='';

  if(categorylistdata.image){
    imagepre = categoryURL+'/'+categorylistdata.image;
  }
  
		
  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
    loaderimg = categoryURL + '/'+categorylistdata.image;
    preview = <img className="img_class" src={loaderimg} alt="" />;
  // alert('hh');
  }else{
    preview = <img className="img_class" src={imagepre} alt="" />;
  }

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="tabmanagement" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Category</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{categorylistdata.name}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{categorylistdata.description}</p>
                        </div>
                      </div>
                     
                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(categorylistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Icon<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          {imagepre!=''?preview:'N/A'}
                        </div>
                      </div>
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));