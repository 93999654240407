import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      dashboardlist:'',
      module_type : localStorage.getItem("module_type"),
      admin_id : localStorage.getItem("admin_id"),
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };

    axios.post(apiUrl+"dashboard/all_counts",qs.stringify(postobject)).then(res => {

      if(res.data.status === "success"){	
        var resp = res.data.response;
        this.setState({ 
          user_count : resp.user_count, 
          broker_count : resp.broker_count, 
          lender_count : resp.lender_count, 
          lead_count : resp.lead_count, 
          sold_lead_count : resp.sold_lead_count, 
          funded_lead_count : resp.funded_lead_count, 
          transaction_count : resp.transaction_count, 
          yearly_earning : resp.yearly_earning, 
          monthly_earning : resp.monthly_earning, 
          });
      
      }
      });
    }

   
    componentDidMount() {
		document.title = PageTitle('Dashboard');
   }



  render() {
    console.log(this.state.module_type,this.state.admin_id,"module_typemodule_type")
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="dashboard" />
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
              <div className="car-listing-wrap dashboard-wrap leaa-admin-head card">

                  <div className="title">
                    <h3>Dashboard</h3>
                  </div>

                  <div className="form-row first">

                        {/* {localStorage.getItem('admin_id') === '1' ?   */}
                        <div className="form-left">
                          <div className="card pull-up border-top-info-red border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className=" card-badge-red card-title-red">{this.state.user_count}</h4>
                              </div>
                              <a href="user" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Users <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                              </a>
                          </div>

                          {/* <div className="card pull-up border-top-info border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className="card-title card-badge">{this.state.broker_count}</h4>
                              </div>
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Brokers <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </div> */}

{this.state.admin_id == 1 && this.state.module_type =='withlenders' ?  <div className="card pull-up border-top-info-yellow border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className="card-title-yellow card-badge-yellow">{this.state.lender_count}</h4>
                              </div>
                              <a href="lender" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Lenders <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                              </a>
                          </div>:null}

                          <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className="card-title-purple card-badge-purple">{this.state.lead_count}</h4>
                              </div>
                              <a href="leads" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Leads <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                              </a>
                          </div>

                          {this.state.admin_id == 1 && this.state.module_type =='withlenders' ? <div className="card pull-up border-top-info border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className="card-title card-badge">{this.state.sold_lead_count}</h4>
                              </div>
                               <a href="leads" className=""> 
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Sold Leads <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                               </a> 
                          </div>:null}

                          {this.state.admin_id == 1 && this.state.module_type =='withlenders' ? <div className="card pull-up border-top-info border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className="card-title card-badge">{this.state.funded_lead_count}</h4>
                              </div>
                               <a href="leads" className=""> 
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Funded Leads <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                               </a> 
                          </div>:null}

                          {this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?   <div className="card pull-up border-top-info border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className="card-title card-badge">{this.state.transaction_count}</h4>
                              </div>
                               <a href="transactionhistory" className=""> 
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Transactions <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                              </a> 
                          </div>:null}

                          {this.state.admin_id == 1 && this.state.module_type ==='withlenders' ? <div className="card pull-up border-top-info border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className="card-title card-badge">{this.state.yearly_earning}</h4>
                              </div>
                              {/* <a href="user" className=""> */}
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Yearly Earnings <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                              {/* </a> */}
                          </div>:null}

                          {this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="card pull-up border-top-info border-top-3 rounded-0">
                              <div className="card-header">
                                  <h4 className="card-title card-badge">{this.state.monthly_earning}</h4>
                              </div>
                              {/* <a href="user" className=""> */}
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Monthly Earnings <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                              {/* </a> */}
                          </div>:null}

                          
                          </div> 
                          {/* : ''} */}
                    </div> 
      
                </div>
          </div>  
      </div>
      
    </div>
    );
  }
}

export default (withRouter(List));