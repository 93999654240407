import login from "./login";
import changepassword from "./changepassword";
import updatesetting from './updatesetting';
import forgetpassword from './forgetpassword';
import profile from './profile';
import updateprofile from './updateprofile';

import companylist from './companylist';
import companyadd from './companyadd';
import companyedit from './companyedit';
import companydetails from './companydetails'

import fontlist from './fontlist';
import fontname from './fontname';
import fontadd from './fontadd';
import userlist from './userlist';
import changestatus from './changestatus';
import fontedit from './fontedit';
import fontdetails from './fontdetails'

import exportuser from './exportuser';
import fontdelete from './fontdelete';
import useredit from './useredit';
import userimport from './userimport';
import userdetails from './userdetails';
import useradd from './useradd';
import userlistview from './userview';



import templatelist from './templatelist';
import templatedetail from './templatedetail';
import templateadd from './templateadd';
import templatedelete from './templatedelete';
import templateedit from './templateedit';



import cmsadd from './cmsadd';
import cmsedit from './cmsedit';
import cmsdetail from './cmsdetail';
import cmslist from './cmslist';
import cmsactive from './cmsactive';

import {packageadd, packageedit, packagedetail, packagelist, packageactive} from './packageAll';

import { combineReducers } from "redux";


const rootReducer = combineReducers({
  login: login,
  updatesetting : updatesetting,
  changepassword: changepassword,
  forgetpassword: forgetpassword,
  profile       : profile,
  updateprofile : updateprofile,
  companylist   : companylist,
  companyadd   : companyadd,
  companyedit   : companyedit,
  companydetails : companydetails,

  fontlist           : fontlist,
  fontname           : fontname,
  fontadd            : fontadd,
  userlist			 : userlist,
  exportuser     : exportuser,
  fontdelete         : fontdelete,
  useredit: useredit,
  userimport: userimport,
  fontedit   : fontedit,
  fontdetails : fontdetails,
  
  userdetails: userdetails,
  
  useradd      : useradd,
  userlistview  : userlistview,
 
  changestatus: changestatus,

    templatelist  : templatelist,
  templatedetail: templatedetail,
  templateadd   : templateadd,
  templatedelete : templatedelete,
  templateedit: templateedit,
  
  cmsadd        : cmsadd,
  cmslist       : cmslist,
  cmsedit       : cmsedit,
  cmsdetail     : cmsdetail,
  cmsactive     : cmsactive,

  packageadd        : packageadd,
  packagelist       : packagelist,
  packageedit       : packageedit,
  packagedetail     : packagedetail,
  packageactive     : packageactive, 

 });

export default rootReducer;
