import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';



const businessTime = [
	{ value: '6', label: 'Less than 6 months' },
	{ value: '12', label: '6-12 months' },
	{ value: '24', label: '1 year' },
	{ value: '60', label: '2-3 years' },
	{ value: '90', label: '3-5 years' },
	{ value: '120', label: 'More than 5 years' }
  ];

 const annualRevenue = [
	{ value: '99999', label: 'Under $100,000' },
	{ value: '175000', label: '$200,000 - $250,000' },
	{ value: '375000', label: '$200,000 - $500,000' },
	{ value: '625000', label: '$500,000 - $750,000' },
	{ value: '875000', label: '$750,000 - $1,000,000' },
	{ value: '2000000', label: '$1,000,000 - $3,000,000' },
	{ value: '3000000', label: '$3,000,000+' }
   ];

 const loanDregrees = [
	{ value: 'Masters', label: 'Masters' },
	{ value: 'MBA', label: 'MBA' },
	{ value: 'PhD', label: 'PhD' },
	{ value: 'JD', label: 'JD' },
	{ value: 'Bachelors', label: 'Bachelors' },
	{ value: 'MD', label: 'MD' },
	{ value: 'DDS', label: 'DDS' },
	{ value: 'LL.M', label: 'LL.M' },
	{ value: 'Associates', label: 'Associates' },
	{ value: 'Bootcamp', label: 'Bootcamp' },
	{ value: 'Other', label: 'Other' }
  ];

const gpaOptions = [
	{ value: '4', label: '4' },
	{ value: '3.5', label: '3.9 to 3.5' },
	{ value: '3', label: '3.5 to 3.0' },
	{ value: '2', label: '3.0 to 2.5' },
	{ value: '2.5', label: '2.5 to 2.0' },
	{ value: '2', label: '2.0 to 1.5' },
	{ value: '1.5', label: '1.5 to 1.0' },
	{ value: '1', label: '1.0 to 0' }


];

const interestRateOption = [
	{ value: '1.18', label: '1.18% - 1.25%' },
	{ value: '1.25', label: '1.25% - 1.35%' },
	{ value: '1.35', label: '1.35% - 1.45%' }
];

const termOption = [
	{ value: '12', label: '12-24 Months' },
	{ value: '8', label: '8-18 Months' },
	{ value: '6', label: '6-9 Months' }
];

// const creditScoreOption = [
// 	{ value: '300', label: '300-579' },
// 	{ value: '580', label: '580-669' },
// 	{ value: '670', label: '670-739' },
// 	{ value: '740', label: '740-799' },
// 	{ value: '800', label: '800-850' }
// ];
export const creditScoreOption = [
	{ value: '750-850', label: 'Above 750' },
	{ value: '720-750', label: '720-750' },
	{ value: '670-720', label: '670-720' },
	{ value: '650-670', label: '650-670' },
	{ value: '620-650', label: '620-650' },
	{ value: '600-620', label: '600-620' },
	{ value: '550-600', label: '550-600' },
	{ value: '500-549', label: 'Below 550' }
  ];

const ratingsOption = [
	{ value: 'A', label: 'A' },
	{ value: 'B', label: 'B' },
	{ value: 'C', label: 'C' },
	{ value: 'D', label: 'D' }
];

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const LoanConfigId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		
		this.state = {
			above_revenue_amount:'',
			revenue_rating:'',
			c_loanconfig_id:LoanConfigId,
            businessTypeOption : [],
			businessTypeValue:[],
			
			Loading:false
		};
	
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");
		
		

    }
	

    // handleChange = (v, action) => {
	// 	console.log(v,"name_type");
	// 	console.log(action,"action");
    //     this.setState({  [action] : v.value});
    // };

	handleChange = selectedOption => {
        this.setState({selectedOption});
		this.setState({  selectedvalue : selectedOption.value});

    };

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
        $('.student_loan').hide();
		document.title = PageTitle('Rating Configuration Edit');
        this.businessType();
        
       
        
    }

    componentDidUpdate(prevProps,prevState) {
       if(prevState.businessTypeOption !== this.state.businessTypeOption){
        this.editValues();
       }
    }

    editValues(){
        
        var qs = require('qs');
		var loanconfigId = this.state.c_loanconfig_id;

		axios.get(apiUrl+"annualRevenueconfig/ratingdetail?id="+loanconfigId).then(res => {
			const formdata = res.data.loanconfiglist;

            if(formdata.annual_revenue){
                var annualRevenueLabel;
                annualRevenue.map(function (data){
                    if(data.value === formdata.annual_revenue)
                    annualRevenueLabel = data.label;
                });
                this.setState({annualRevenueValue:{value: formdata.annual_revenue, label: annualRevenueLabel}});
            }
           

            if(formdata.rating){
                var ratingLabel;
                ratingsOption.map(function (data){
                    if(data.value === formdata.rating)
                    ratingLabel = data.label;
                });
                this.setState({ratingValue:{value: formdata.rating, label: ratingLabel}});
            }
            

            if(formdata.business_type){
                var businessTypeLabel =[];
                var businessTypeValueST =[];
            
                var businessTypeOptionT = this.state.businessTypeOption;
                JSON.parse(formdata.business_type).map(function (data,index){
                    for(var key in businessTypeOptionT){
                        if(businessTypeOptionT[key].value === data){
                            businessTypeLabel.push(businessTypeOptionT[key]);
                            businessTypeValueST.push(businessTypeOptionT[key].value);
                       }
                    }
                });
                this.setState({businessTypeValue:businessTypeLabel,businessType : businessTypeValueST});
            }
           
            
            if(formdata.interest_rate){
                var interestRateLabel;
                interestRateOption.map(function (data){
                    if(data.value === formdata.interest_rate)
                    interestRateLabel = data.label;
                });
                this.setState({interestRateValue:{value: formdata.interest_rate, label: interestRateLabel}});
            }
			
            if(formdata.term){
                var termLabel;
                termOption.map(function (data){
                    if(data.value === formdata.term)
                    termLabel = data.label;
                });
                this.setState({termValue:{value: formdata.term, label: termLabel}});
            }
            
            if(formdata.time_in_business){
                var businessTimeLabel;
                businessTime.map(function (data){
                    if(data.value === formdata.time_in_business)
                    businessTimeLabel = data.label;
                });
                this.setState({businessTimeValue:{value: formdata.time_in_business, label: businessTimeLabel}});
            }
            

            if(formdata.credit_score){
                var creditScoreLabel;
                creditScoreOption.map(function (data){
                    if(data.value === formdata.credit_score)
                    creditScoreLabel = data.label;
                });
                this.setState({creditScoreValue:{value: formdata.credit_score, label: creditScoreLabel}});
            }
           


					
			this.setState({above_revenue_amount: formdata.above_revenue_amount});
			this.setState({revenue_rating: formdata.revenue_rating});
			this.setState({status: formdata.selectedOption});
		}); 
    }

        handleFormSubmit = () => {
            
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				
				
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					business_type:formPayload.businessType,
					time_in_business:formPayload.businessTimeValue.value,
					annual_revenue:formPayload.annualRevenueValue.value,
					rating:formPayload.ratingValue.value,
					interest_rate:formPayload.interestRateValue.value,
					term:formPayload.termValue.value,
					credit_score:formPayload.creditScoreValue.value,
                    id: this.state.c_loanconfig_id,
					// status: formPayload.selectedvalue

				};
				// console.log(postObject,'postObject')
				axios.post(apiUrl+"annualRevenueconfig/edit_rating",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.loanconfiglist;
						if(formpayload.status === 'Active'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			
						}
						this.setState({above_revenue_amount: formpayload.above_revenue_amount});
						this.setState({revenue_rating: formpayload.revenue_rating});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}
	handlePriceChange = (e) => {
		const inputValue = e.target.value;
	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			amount: priceWithSymbol
		  });
		}
	  };
      validateForm() {
		
		const {ratingValue,annualRevenueValue,businessTypeValue,businessTimeValue,interestRateValue,termValue,creditScoreValue} = this.state;
		let errors = {};
      	let formIsValid = true;


		if (!interestRateValue) {
			formIsValid = false;
			$('.errorinterest_rate').html('<span class="errorspan">Please select the interest rate.</span>');
		}else {
			$('.errorinterest_rate').html('');
		}

		if (!creditScoreValue) {
			formIsValid = false;
			$('.errorcredit_score').html('<span class="errorspan">Please select the credit score.</span>');
		}else {
			$('.errorcredit_score').html('');
		}


		
		if (!termValue) {
			formIsValid = false;
			$('.errorterm').html('<span class="errorspan">Please select the term.</span>');
		}else{
			$('.errorterm').html('');
		}

			if (!businessTypeValue) {
				formIsValid = false;
				$('.errorbusiness_type').html('<span class="errorspan">Please select the business category.</span>');
			}else if(businessTypeValue){
				$('.errorbusiness_type').html('');
			}

			if (!businessTimeValue) {
				formIsValid = false;
				$('.errortime_in_business').html('<span class="errorspan">Please select the time of business.</span>');
			}else {
				$('.errortime_in_business').html('');
			}
		
		
		
			if (!annualRevenueValue) {
				formIsValid = false;
				$('.errorannual_revenue').html('<span class="errorspan">Please select the annual revenue</span>');
			}else {
					$('.errorannual_revenue').html('');
			}
		
		

		if (!ratingValue) {
			formIsValid = false;
			$('.errorrating').html('<span class="errorspan">Please enter the rating.</span>');
		}else {
				$('.errorrating').html('');
		}

		

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(){

		
    }

    setValues = (v,action) =>{ 
		this.setState({
			 [action]: v
		 });

		 
	}

	handleMultiSelect = (newValue: any, actionMeta: any) => {
		

		if(actionMeta.name === "businessType"){
			this.setState({ businessTypeValue: newValue });
            var newValuesArr = newValue ? newValue.map(item => item.value) : [];
            this.setState({ businessType: newValuesArr });
		}
		
	  };

      businessType = () => {
		axios.get(apiUrl+'businesstypes/dropdown_list').then(res => {
			if(res.data.status === "success"){				
				this.setState({					
					businessTypeOption:res.data.bt_dd_obj
				});
			 }				 
			 
	   });	
	}
	
  render() {
  	console.log(this.state.businessTypeValue,'businessTypeValue')
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="ratingCalculation" />  

	
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
		<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
            <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				
				
				
			<div className="form-group z-index-999">					
					<label>Rating<span class="required" style={{ color: "red" }} > * </span></label>
					<Select 
                     value={this.state.ratingValue?this.state.ratingValue:{ value: '', label: 'Rating'}}
                    className="fund-app-drop"  placeholder="Rating" options={ratingsOption} onChange={(values) => this.setValues(values,'ratingValue')}   name="rating" isSearchable={ false }  />
					<div className="errorrating"></div>
				</div>
				{/* <div className="form-group"> 
					<label>Annual Amount<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="number"  name="above_revenue_amount" onChange={this.handleInputChange} className="form-control" value={this.state.above_revenue_amount} />
					<div className="errorabove_revenue_amount"></div>
				</div>*/}

				{/* <div className="default-font">
					<div className="form-group">					
						<label>Loan Type<span class="required" style={{ color: "red" }} > * </span></label>
								<Select className="fund-app-drop"  placeholder="Loan Type" options={this.state.loanTypesOption} onChange={(values) => this.setValues(values,'loanType')}   name="loanType" isSearchable={ false }  />
								<div className="errorloan_type"></div>
					</div>
				</div> */}

				<div className="default-font business_loan">
					<div className="form-group z-index-998">					
						<label>Business Type<span class="required" style={{ color: "red" }} > * </span></label>
							
								<Select className="fund-app-drop"  
                                value={this.state.businessTypeValue}
                                isMulti
                                placeholder="Business Type" options={this.state.businessTypeOption} onChange={this.handleMultiSelect}  name="businessType" isSearchable={ false }  />
								<div className="errorbusiness_type"></div>
					</div>
				</div>	


				<div className="default-font business_loan">
					<div className="form-group z-index-997">					
						<label>Interest Rate<span class="required" style={{ color: "red" }} > * </span></label>
						
								<Select className="fund-app-drop" 
                                 value={this.state.interestRateValue?this.state.interestRateValue:{ value: '', label: 'Interest rate'}}
                                 placeholder="Interest Rate" options={interestRateOption} onChange={(values) => this.setValues(values,'interestRateValue')}  name="interestRate" isSearchable={ false }  />
								<div className="errorinterest_rate"></div>
					</div>
				</div>

				

				<div className="default-font business_loan">
					<div className="form-group z-index-996">					
						<label>Credit Score<span class="required" style={{ color: "red" }} > * </span></label>
						
								<Select className="fund-app-drop" placeholder="Interest Rate" 
                                 value={this.state.creditScoreValue?this.state.creditScoreValue:{ value: '', label: 'Credit Score'}}
                                 options={creditScoreOption} onChange={(values) => this.setValues(values,'creditScoreValue')}  name="creditScore" isSearchable={ false }  />
								<div className="errorcredit_score"></div>
					</div>
				</div>
				
				<div className="default-font student_loan">
					<div className="form-group z-index-995">					
						<label>GPA<span class="required" style={{ color: "red" }} > * </span></label>
							
								<Select className="fund-app-drop" placeholder="GPA" options={gpaOptions} onChange={(values) => this.setValues(values,'gpa')}  name="gpa" isSearchable={ false }  />
								<div className="errorgpa"></div>
					</div>
				</div>

			</div>
			<div className="form-right">

				
				{/* <div className="default-font business_loan">
					<div className="form-group">					
						<label>Business Category<span class="required" style={{ color: "red" }} > * </span></label>
								<Select className="fund-app-drop" placeholder="Business Category" options={this.state.businessCategoryOption}  onChange={(values) => this.setValues(values,'businessCategory')}  name="businessCategory" isSearchable={ false }  />
								<div className="errorbusiness_category"></div>
					</div>
				</div>	 */}

				<div className="form-group z-index-999 annual_revenue">					
					<label>Annual Revenue<span class="required" style={{ color: "red" }} > * </span></label>
					<Select className="fund-app-drop" placeholder="Annual Revenue" options={annualRevenue} 
                    
                    value={this.state.annualRevenueValue?this.state.annualRevenueValue:{ value: '', label: 'Annual Revenue'}}
                    onChange={(values) => this.setValues(values,'annualRevenueValue')}  name="annualRevenue" isSearchable={ false }  />
					<div className="errorannual_revenue"></div>
				</div>


				


				<div className="default-font business_loan">
					<div className="form-group z-index-998">					
						<label>Time in Business<span class="required" style={{ color: "red" }} > * </span></label>
						
								<Select className="fund-app-drop" 
                                 value={this.state.businessTimeValue?this.state.businessTimeValue:{ value: '', label: 'Time in Business'}}
                                 placeholder="Time in Business" options={businessTime} onChange={(values) => this.setValues(values,'businessTimeValue')}  name="businessTime" isSearchable={ false }  />
								<div className="errortime_in_business"></div>
					</div>
				</div>
				

				<div className="form-group z-index-997 annual_revenue">					
					<label>Term<span class="required" style={{ color: "red" }} > * </span></label>
                    
					<Select className="fund-app-drop" 
                    value={this.state.termValue?this.state.termValue:{ value: '', label: 'Term'}}
                    placeholder="Term" options={termOption} onChange={(values) => this.setValues(values,'termValue')}  name="term" isSearchable={ false }  />
					<div className="errorterm"></div>
				</div>

				<div className="default-font student_loan">
					<div className="form-group z-index-996">					
						<label>Type of Degree<span class="required" style={{ color: "red" }} > * </span></label>
							{/* <Select 
								options={lang.common.status_option} 
								onChange={(values) => this.setValues(values,'status')} 
								/> */}
								<Select className="fund-app-drop" isMulti placeholder="Type of Degree" options={loanDregrees} onChange={this.handleMultiSelect}  name="degreeType" isSearchable={ false }  />
								<div className="errortype_of_degree"></div>
					</div>
				</div>
					

				
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));