import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from 'ckeditor4-react';

	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			faq_question:'',
			faq_answer:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedCateOption: '',
			selectedvalue:'active',
			selectedCatevalue:''
			
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            faq_answer: evt.editor.getData()
        } );
		}, 1000);
     
    }

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'cate'){			
			this.setState({selectedCateOption:selOption})
			this.setState({  selectedCatevalue : selOption.value});
		}
		console.log(selOption.value, 'sdfg')
	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  
  handleSubmit = () => {
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status, cate = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	if(formdata.selectedCatevalue === '' && formdata.selectedCatevalue!== 0){
		cate =formdata.selectedCateOption.value;
	}else{
		cate = formdata.selectedCatevalue;
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		faq_question : formdata.faq_question,
		faq_answer:formdata.faq_answer,
		status: status,
		cate_id:cate
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"faq/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/Faq'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

  validateForm() {
	const {faq_question,faq_answer, selectedCateOption} = this.state;

	let errors = {};
	  let formIsValid = true;

	  if(!faq_question){
		formIsValid = false;
		$('.errorfaq_question').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorfaq_question').html('');
	}

	if(!faq_answer){
		formIsValid = false;
		$('.errorfaq_answer').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorfaq_answer').html('');
	}
	

	if(!selectedCateOption){
		formIsValid = false;
		$('.errorcate').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorcate').html('');
	}
		
	this.setState({
		errors: errors
	});
	return formIsValid;
}



  render() {
	const {selectedOption,selectedCateOption} = this.state;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Faq" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Faq</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">

				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedCateOption?selectedCateOption:""}
                            options={lang.common.categories_option} 
                            onChange={this.handleChange.bind(this, 'cate')}
                            />
							<div className="errorcate"></div>
	           </div> 
				
				
				<div className="form-group">					
					<label>Answer<span class="required" style={{ color: "red" }} > * </span> :</label>
						<textarea type="text" name='faq_answer' onChange={this.handleInputChange} className='form-control' value={this.state.faq_answer} />
						{/* <CKEditor
					data={this.state.faq_answer}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					<div className="errorfaq_answer"></div>
				</div>
			</div>
                  
			<div className="form-right">
			
			
				<div className="form-group">
					<label>Question<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="faq_question" onChange={this.handleInputChange} className="form-control" value={this.state.faq_question}/>
					<div className="errorfaq_question"></div>
				</div>
				
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));