import React, { Component, } from 'react';
import { lang } from'../Helpers/lang';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Select from 'react-select';
import $ from 'jquery';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl, mediaUrl} from'../Config/Config';
import axios from 'axios';
/*const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };*/
class Edit extends Component {
	
	constructor(props)
	{
		super(props);
		//const id = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		const id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
		  list_id : id,
	      login_type:'',
	      show_count_yes:true,
	      show_count_no:false,
	      name:'',
	      module_type:'',
	      module_type_value:'',
	      priorty:'',
		  content:'',
		  image_preview:'',
		  image_name:'',
		  image:'',
	      name_arr:[],
	      module_type_arr:[],
	      module_type_value_arr:[],
	      priorty_arr:[],
		  content_arr:'',
		  image_preview_arr:'',
		  image_name_arr:'',
	      showlayout: false,
	      select_options:'',
	      select_options_arr:[],
	      selectedvalue: '',
          selectedOption:{ value: 'active', label: 'Active'},
          Loading:false,
		  show_count_arr:[],
		  image_arr:[],
          editindex:'',
          field_ids:[],
		  remove_ids:'',
		  removeall_ids:'',
		  login_type:'',
		  image_name_single:'',
		  show_count: 'yes',
		};
    this.deletesinglefield = this.deletesinglefield.bind(this);

	var qs = require('qs');
	var listid = this.state.list_id;

	axios.get(apiUrl+"dashboardmanagment/listdetail?id="+listid).then(res => {
		const formdata = res.data.data;
						this.setState({login_type : {value:formdata.usertype, label:formdata.usertype}, showlayout: true, login_type_value: formdata.usertype});
						
					  	if(formdata.field){
					  		this.setState({name_arr: formdata.field});
					  	}
					  	if(formdata.priority){
					  		this.setState({priorty_arr: formdata.priority});
					  	}
					  	if(formdata.select_option){
						
					  		this.setState({module_type_value_arr : formdata.select_option});
					  	}
					  	if(formdata.image){
					  		this.setState({image_name_arr: formdata.image, image_arr: formdata.image});
					  	}
					  	if(formdata.column_id){
					  		this.setState({field_ids: formdata.column_id});
					  	}
					  	if(formdata.show_count){
					  		this.setState({show_count_arr: formdata.show_count});
					  	}
		
	
	}); 
 }
	
	componentDidMount() {
		  	if(localStorage.getItem("admin_id") === 'undefined' && localStorage.getItem('admin_id') === '' && localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
		}
  }

  getdashbordlistdetails = () => {
	var qs = require('qs');
	var listid = this.state.list_id;

	axios.get(apiUrl+"dashboardmanagment/listdetail?id="+listid).then(res => {
		const formdata = res.data.data;
						this.setState({login_type : {value:formdata.usertype, label:formdata.usertype}, showlayout: true, login_type_value: formdata.usertype});
						
					  	if(formdata.field){
					  		this.setState({name_arr: formdata.field});
					  	}
					  	if(formdata.priority){
					  		this.setState({priorty_arr: formdata.priority});
					  	}
					  	if(formdata.select_option){
						
					  		this.setState({module_type_value_arr : formdata.select_option});
					  	}
					  	if(formdata.image){
					  		this.setState({image_name_arr: formdata.image, image_arr: formdata.image});
					  	}
					  	if(formdata.column_id){
					  		this.setState({field_ids: formdata.column_id});
					  	}
					  	if(formdata.show_count){
					  		this.setState({show_count_arr: formdata.show_count});
					  	}
		
	
	}); 
  }
	handleInputChange = (event) =>{
    const { name,value } = event.target;
    this.setState({[name]: value})
     if(name === 'form_column'){
       this.setState({form_one_column: !this.state.form_one_column});
       this.setState({form_two_column: !this.state.form_two_column});
    }
    if(name === 'field_required'){
        this.setState({field_required_checked: !this.state.field_required_checked});
    }
    if(name == 'select_options'){
        this.setState({select_options:value})
    }
	if (name === 'show_count') {
		this.setState({ show_count: value });
	  }
  }

  handleChange = login_type => {
	this.setState({login_type})
	this.setState({  login_type_value : login_type.value});
};

 
 handleFormSubmit = () => {
	
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
		
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					list_id : this.state.list_id,
					field_ids : formPayload.field_ids,
					user: formPayload.login_type_value,
					name_arr: formPayload.name_arr,
					module_type_value_arr: formPayload.module_type_value_arr,
					priorty_arr: formPayload.priorty_arr,
					show_count_arr:formPayload.show_count_arr,
					content_arr: formPayload.content_arr,
					removeid: formPayload.remove_ids,
					removeallid: formPayload.removeall_ids,
				};
				//this.props.getEditForm(qs.stringify(postObject));
				
				let formData = new FormData();
		
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
				var filesdata = this.state.image_arr;
				// console.log(filesdata,'filesdata')
			     	formPayload.name_arr.forEach((row, index) => {
						filesdata.forEach((image, i) => {
							if(index == i){
								formData.append(`update_image[${index}]`, image);
							}
							
						});
					});
				 
				axios.post(apiUrl+"dashboardmanagment/edit",formData,config).then(res => {
					if(res.data.status === "success"){
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
									setTimeout(
									function() {
										// $('.success_message').html(this.props.history.push('/dashboardmanagement'));
										$('.success_message').html('');
										this.getdashbordlistdetails();
										this.setState({Loading:false});
									}
									.bind(this),
									3000
									);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
					});

	}


  Addtolayout=()=>{

	  	var showcount;
		if(this.state.show_count_yes == true){
			showcount = 'yes';
		}else{
			showcount = 'no';
		}
  		var Index = this.state.editindex;
		let fieldname = [...this.state.name_arr];
		let fieldtype = [...this.state.module_type_value_arr];
		let priority = [...this.state.priorty_arr];
		let content = [...this.state.content_arr]; 
		let image = [...this.state.image_arr]; 
		
		let show = [...this.state.show_count_arr]; 
		if(Index !==''){
		
			fieldname.splice(Index, 1,this.state.name);	
			fieldtype.splice(Index, 1,this.state.module_type_value);
			priority.splice(Index, 1,this.state.priorty);
			content.splice(Index, 1,this.state.content);
			image.splice(Index,1,this.state.image);
			show.splice(Index,1,show);
			this.setState({name_arr: fieldname,module_type_value_arr: fieldtype,content_arr:content,priorty_arr:priority,image_arr:image,show_count_arr:show});
		}else{
			
			this.setState({
				name_arr: this.state.name_arr.concat(this.state.name), module_type_value_arr: this.state.module_type_value_arr.concat(this.state.module_type_value),priorty_arr: this.state.priorty_arr.concat(this.state.priorty),content_arr: this.state.content_arr.concat(this.state.content),image_arr: this.state.image_arr.concat(this.state.image)
				});
				// if(this.state.show_count_yes == true){
				// 	var show_count = 'yes';
				// }else{
				// 	var show_count = 'no';
				// }
				this.setState({show_count_arr: this.state.show_count_arr.concat(this.state.show_count)})
		}
	    
	
		this.setState({showlayout: true},function(){
		this.setState({name:'',module_type:'',module_type_value:'',priorty:'',content:'',editindex:'',image_name_single:''});
		this.getformlayout();
		scrollToTop();
		$('#addbutton').html('Add');
		});
  }

  validateFormfield() {
		const {form_field_name,form_input_type_value,field_required,field_priorty,select_options} = this.state;
		let errors = 0;
	
		if (!form_field_name) {
			errors++;
			$('.errorfieldname').html('<span class="errorspan">Please fill the field</span>');
		}else if(form_field_name){
			$('.errorfieldname').html('');
		}


		if (!form_input_type_value) {
			errors++;
			$('.errortype').html('<span class="errorspan">Please select input type</span>');
		}else if(form_input_type_value){
			$('.errortype').html('');
		}

		if(!field_priorty){
		errors++;
			$('.errorpriority').html('<span class="errorspan">Please fill the field</span>');
		}else if(field_priorty){
			$('.errorpriority').html('');
		}

		if(form_input_type_value == 'select'){
			if(!select_options){
				errors++;
				$('.erroroptions').html('<span class="errorspan">Please fill the field</span>');
			}else if(select_options){
				$('.erroroptions').html('');
			}
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }

       componentWillReceiveProps(Props){

    	   if(Props.formdetail !== this.props.formdetail){
    		if(Object.keys(Props.formdetail).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.formdetail[0].status === "success"){

						const formdata = Props.formdetail[0].formdata;
						this.setState({form_name : formdata.formname});
						if(formdata.formcolumn == 1){
							this.setState({form_one_column: true,form_two_column: false});
						}else{
							this.setState({form_two_column: true,form_one_column:false})
						}
						if(formdata.formstatus === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
						}
					
					  	if(formdata.field){
					  		this.setState({name_arr: formdata.field,showlayout:true});
					  	}
					  	if(formdata.priority){
					  		this.setState({field_priorty_arr: formdata.priority});
					  	}
					  	if(formdata.required){
					  		this.setState({field_required_arr : formdata.required});
					  	}
					  	if(formdata.fieldtype){
					  		this.setState({form_input_type_value_arr: formdata.fieldtype});
					  	}
					  	if(formdata.field_id){
					  		this.setState({field_ids: formdata.field_id});
					  	}
					  	if(formdata.options){
					  		this.setState({select_options_arr: formdata.options});
					  	}
					}
    		}
    	}

    if(Props.formedit !== this.props.formedit){

    		if(Object.keys(Props.formedit).length > 0){

    			this.setState({ Loading: false });

    			if(Props.formedit[0].status === "success"){
    				

						const formdata = Props.formedit[0].formdata;
						this.setState({form_name : formdata.formname});
						if(formdata.formcolumn == 1){
							this.setState({form_one_column: true,form_two_column: false});
						}else{
							this.setState({form_two_column: true,form_one_column:false})
						}
						if(formdata.formstatus === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
						}
					
					  	if(formdata.field){
					  		this.setState({name_arr: formdata.field,showlayout:true});
					  	}
					  	if(formdata.priority){
					  		this.setState({field_priorty_arr: formdata.priority});
					  	}
					  	if(formdata.required){
					  		this.setState({field_required_arr : formdata.required});
					  	}
					  	if(formdata.fieldtype){
					  		this.setState({form_input_type_value_arr: formdata.fieldtype});
					  	}
					  	if(formdata.field_id){
					  		this.setState({field_ids: formdata.field_id});
					  	}
					  	if(formdata.options){
					  		this.setState({select_options_arr: formdata.options});
					  	}

					  	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.formedit[0].message+'</h3></div>');
	                	scrollToTop();
	    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					
    			}else{
    			$('.success_message').html('<div class="status_sucess"><h3>'+ Props.formedit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		 }
    	}
    }
  }
  deletesinglefield(Index, id){
      let fieldname = [...this.state.name_arr];
      let fieldtype = [...this.state.module_type_value_arr];
      let showcount = [...this.state.show_count_arr];
      let fieldpriorty = [...this.state.priorty_arr]; 
	  let content = [...this.state.content_arr]; 
      let fieldids = [...this.state.field_ids]; 
      fieldname.splice(Index, 1);
      fieldtype.splice(Index, 1);
      showcount.splice(Index, 1);
      fieldpriorty.splice(Index, 1);
      content.splice(Index, 1);
      fieldids.splice(Index, 1);
      this.setState({name_arr: fieldname,module_type_value_arr: fieldtype,show_count_arr:showcount,priorty_arr:fieldpriorty,content_arr:content,field_ids:fieldids});
	  this.setState(prevState => ({
		remove_ids: [...prevState.remove_ids, id]
	  }))
  }
  editsinglefield = (index) => {
	
  		this.setState({name:this.state.name_arr[index],priorty:this.state.priorty_arr[index],content: this.state.content_arr[index],editindex: index});

  		var fieldtype = this.state.module_type_value_arr[index];

  		// var fieltypeval =fieldtype.charAt(0).toUpperCase() + fieldtype.slice(1);
  		
  		// this.setState({form_input_type: {value: fieldtype, label: fieltypeval},form_input_type_value: fieldtype});
		  var contr =[];
		  for(let i=0; i<lang.common.field_options.length; i++){
		  if(lang.common.field_options[i].value == this.state.module_type_value_arr[index]){
		 	 contr.push(lang.common.field_options[i])
		  }
		  }
		  this.setState({module_type: contr,module_type_value: this.state.module_type_value_arr[index]})
  		if(this.state.show_count_arr[index] == 'yes'){
  			this.setState({show_count : 'yes',show_count_no: false})
  		}else{
			this.setState({show_count : 'no',show_count_no: true})
  		}
  		if(this.state.select_options_arr[index]!==''){
  			this.setState({select_options:this.state.select_options_arr[index]});
  		}
		if(this.state.image_name_arr[index]){
			this.setState({image_name_single:this.state.image_name_arr[index],image_preview:''});
		}else{
			this.setState({image_name_single:'',image_preview:''});
		}
		$('#addbutton').html('Update');
  }
  getformlayout(){
      var form_field_value = this.state.name_arr;
      if (Object.keys(form_field_value).length > 0) {
      const formlayout = form_field_value.map(
      (datas, Index) => {
      let indexdata = Index+1;
      return (
            <div key={Index+'list'}>
            <li className="sortablecontainer-module-list">
            {/* <i class="fa fa-chevron-right" aria-hidden="true"></i> */}
			<b>{indexdata}. </b>
            {datas}
            <div className="sortablecontainer-module-edit-button">
            <button onClick={this.editsinglefield.bind(this,Index)} >Edit</button>
            <button onClick={this.deletesinglefield.bind(this,Index, this.state.field_ids[Index])}>Delete</button>
            </div>
            </li></div> );

      }
      );
      return formlayout;
      }
  }
  getdeleteall = (ids) =>{
	  this.setState({showlayout:false,name_arr:[],form_input_type_arr:[],form_input_type_value_arr:[],field_priorty_arr:[],field_required_arr:[],select_options_arr:[]});
	  this.setState(prevState => ({
		removeall_ids: [...prevState.removeall_ids, ids]
	  }))
  }

  handlefieldTypeChange(event) {   
	this.setState({module_type:[event],module_type_value:event.value})
}
onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		image_name_single:''
	});
	};
	reader.readAsDataURL(file);
}

  render() {
  const {selectedOption,showlayout,login_type, image_name_single} = this.state;
			let preview = '';
		
			if (image_name_single !=='') {
				const imagepre = mediaUrl+'dashboard/'+this.state.image_name_single;
				preview = <img className="img_class" src={imagepre} alt="" width={100} height={100}/>;
			}

			if(this.state.image_preview!==''){
					preview = <img className="img_class" src={this.state.image_preview} alt="" width={100} height={100}/>;
			}
  return (
   <div className="wrapper"> 
    <Header />
    <MenuSidebar  currentpage="dashboardmanagement" />		
    	<div className="content">	
    		<div className="content-wrapper">
			<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
    			<div className="success_message"></div>
					<div className="title">
						<h4>Edit {this.state.login_type.value} dasboard</h4>
					</div>
    			<div className="content-body">
  			
        			<div className="builderpage-module-pagewrapper">
                <section className="builderpage-module-section">
				{this.state.name_arr.length > 0 && <> {(this.state.showlayout == true)?<> <h2 className="typography-module-title">{this.state.login_type_value} Menus <div className="sortablecontainer-module-delete-btn"><button onClick={this.getdeleteall.bind(this, this.state.field_ids)}>Delete All</button></div></h2>
				
                       <div className="sortablecontainer-module-wrapper">
                         <ul>
                           {this.getformlayout()}
                         </ul>
                         
                      </div></>:''}</>}
                  </section>
				  <form className="BuilderPage-module-form builderpage-module-section" onSubmit={ (e) => { e.preventDefault(); this.handleFormSubmit(this); }}>

						<div className="form-group">
							<label>User Type:</label>
							<input type="text" className="form-control" placeholder="Login Type" name="login_type" value={this.state.login_type.value} onChange={this.handleInputChange} autoComplete="off" readOnly/>
						</div>
						{/* <div className="form-group">					
						<label>User Type :</label>
						<Select 
						value={this.state.login_type.value}
						options={lang.common.login_type} 
						onChange={this.handleChange}
						isDisabled ={true}
						/>
						</div> */}
						{/* <div className="form-group radio-check">
						<label>Choose layout</label>
								<input className="form-check-input" type="radio" name="show_count" id="show_count_yes" checked/>
								<label className="form-check-label" for="show_count_yes">
								One Column
								</label>
								<input className="form-check-input" type="radio" name="show_count" id="show_count_no" />
								<label className="form-check-label" for="show_count_no">
								Two Column
								</label>
						</div> */}
						{/* <div className="form-group">					
						<label>Status:</label>
								<Select 
								value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange}
								/>
						</div> */}
						<h2 className="typography-module-title">Add Menu</h2>
						<div className="form-group">
						<label>Menu:</label>
						<input type="text" name="name" className="form-control" value={this.state.name} onChange={this.handleInputChange} autoComplete="off" />
						<div className="errorname"></div>
						</div>
						<div className="form-group">
						<label>Link To: </label>
						<Select 
						value={this.state.module_type!=''?this.state.module_type:'Choose'}
						options={lang.common.field_options} 
						onChange={this.handlefieldTypeChange.bind(this)}
					
						isSearchable={false}
						/>
						<div className="errormodule"></div>
						</div>
						{/* {(this.state.module_type_value == 'select' || this.state.module_type_value == 'checkbox' || this.state.module_type_value == 'radio')?<div className="form-group">
						<label>Options:</label>
						<input type="text" name="select_options" className="form-control" value={this.state.select_options} placeholder="Enter options separate by ," onChange={this.handleInputChange} autoComplete="off" />
						<div className="erroroptions"></div>
						</div>:''} */}
						
						{/* <div className="form-group">
							<label>Content : </label>
							<input type="text" name="content" className="form-control" value={this.state.content} placeholder="Content" onChange={this.handleInputChange} autoComplete="off" />
						</div> */}

						<div className="form-group">
						<label>Image:</label>
						<div className="choose-file">
						{this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
						</div>
						{preview!== '' && preview}
						{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
						<div className="errorloaderimage"></div>  
						</div> 
						{/* <div className="form-group radio-check">
								<label>Show Count</label>
								<input className="form-check-input" type="radio" name="show_count" id="show_count_yes" onChange={this.handleInputChange} checked={(this.state.show_count_yes == true ? "checked" : "" )}/>
								<label className="form-check-label" for="show_count_yes">
								Yes
								</label>
								<input className="form-check-input" type="radio" name="show_count" onChange={this.handleInputChange}  id="show_count_no" checked={(this.state.show_count_no == true ? "checked" : "" )} />
								<label className="form-check-label" for="show_count_no">
								No
								</label>
						</div> */}
						<div className="form-group radio-check">
								<label>Show Count</label>
								<input className="form-check-input" type="radio" name="show_count" id="show_count_yes" value="yes" onChange={this.handleInputChange} checked={this.state.show_count === 'yes'} />
									<label className="form-check-label" htmlFor="show_count_yes"> Yes </label>
								<input className="form-check-input" type="radio" name="show_count" id="show_count_no" value="no" onChange={this.handleInputChange} checked={this.state.show_count === 'no'} />
								<label className="form-check-label" htmlFor="show_count_no"> No</label>
      						</div>
						<div className="form-group">
							<label>Priority : </label>
							<input type="text" name="priorty" className="form-control" value={this.state.priorty}  onChange={this.handleInputChange} autoComplete="off" />
							<div className="errorpriority"></div>
						</div>
						<div className='product-subscription-form-row'>
						<div className="submit-button">
						<span className="btn btn_orange btn_minwid login_submit animate-btn2" id="addbutton" onClick={this.Addtolayout}>Add</span>
						</div>
						<div className="">	
						<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
											<span className="load-data"></span> 
											}Submit
						</button>
						</div>
						</div>
						</form>
          </div>
  			</div>	
			</div>
  		</div>	
  	</div>
  </div>
  );
  }
  }

export default (withRouter(Edit));