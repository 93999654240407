import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $, { parseJSON } from 'jquery';
import Select from 'react-select';
import CKEditor from 'ckeditor4-react';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { mediaUrl,apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const guideId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            guideId: guideId,			
			description:'',
			title:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			image_name: [],
			image_preview: [],
			image:[],
			old_image:[],
			image_fileExtension:[],
			allImgArray : [],
			apply_on_maintab : false,
			apply_on_innertab : false,
			old_tab_icon : ''
		};
		// this.props.getCategoryDetailList(guideId);
		var qs = require('qs');
		var catid = this.state.guideId;

		axios.get(apiUrl+"guide/listdetail?id="+catid).then(res => {
			
			this.setState({title:res.data.guidelist.name,description:res.data.guidelist.short_desc,tab_icon:res.data.guidelist.image});
			if(res.data.guidelist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}			
			if(res.data.guidelist.tab_icon !== null){
				this.setState({old_tab_icon:res.data.guidelist.image});
			}

			if(res.data.guidelist.image != ''){
				
				this.setState({old_image:parseJSON(res.data.guidelist.image)});
			}
			
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeMultipleHandler = this.onChangeMultipleHandler.bind(this);

    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Guide Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),				
					name : formPayload.title,
					description: localStorage.getItem("tab_content"),					
					status:  status,					
					id : this.state.guideId,
					old_tab_icon : this.state.old_tab_icon,
				
				};
				let formData = new FormData();
				
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				var imgdata = formPayload.allImgArray;
				for (var i in imgdata) {
					formData.append('images[]',imgdata[i])
				}
				var oldImgdata = (formPayload.old_image.length > 0) ? formPayload.old_image : '';				
				formData.append('old_images',oldImgdata)
				


				var icondata = formPayload.icon;
				formData.append('file',icondata)
				
				axios.post(apiUrl+"guide/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/guide'));
								}
								.bind(this),
								3000
								);
								localStorage.removeItem('tab_content');
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {title}   = this.state;
		let errors = {};
      	let formIsValid = true;
		var description = localStorage.getItem("tab_content");
		
		if (!title) {
			formIsValid = false;
			$('.errortab_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(title){
			$('.errortab_title').html('');
		}

		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
    }


	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			icon_name: file.name,
			icon_preview: reader.result,
			icon: file,
			icon_fileExtension:extension,
			
		  });
		};
		reader.readAsDataURL(file);
		
	  } 

	  onChangeMultipleHandler= e => {
		this.fileObj = [];
		this.fileNameArray = this.state.image_name;
		this.fileArray = this.state.image;
		this.extensionArray = this.state.image_fileExtension;
		this.image_preview = this.state.image_preview;
		this.allImgArray = this.state.allImgArray;
		this.fileObj.push(e.target.files);
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileNameArray.push(this.fileObj[0][i].name)
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			this.allImgArray.push(this.fileObj[0][i])
			this.extensionArray.push(this.fileObj[0][i].name.split('.').pop());
			
		}
		
		this.setState({
			image_name: this.fileNameArray,
			image_preview: this.image_preview,
			image: this.fileArray,
			image_fileExtension:this.extensionArray,
			
		  });
		
	  }
	  
	removeImagebyindex = (indexvalue) => {
		var list = this.state.image;
		var allImgArray = this.state.allImgArray;
		
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
		allImgArray.splice(indexvalue,1)

	
		this.setState({ image: list , allImgArray : allImgArray })
		
	}

	OldremoveImagebyindex = (indexvalue) => {
		var list = this.state.old_image;
		console.log(list,'list')
		list.splice(indexvalue, 1);
		console.log(list,'list')

		this.setState({ old_image: list })
		
	}

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData())
    	// var callfunc = this;
    	// setTimeout(function () {
		// callfunc.setState( {
        //     terms_and_condition: evt.editor.getData()
        // } );
		// }, 1000);
		localStorage.setItem('tab_content',evt.editor.getData())
     
    }

  render() {

  	  const {selectedOption} = this.state;
	 
		var oldImgThumblist;
		var imgThumblist ;
		let indexlength =  this.state.image.length;
		if(this.state.old_image.length > 0){
			oldImgThumblist =  this.state.old_image.map((url,index) => (
				<li className="thumb" key={indexlength+index}  >
					<span><img src={mediaUrl+'gallery/'+url}  alt="..." /></span>
					<a href="javascript:void(0);" onClick={this.OldremoveImagebyindex.bind(this,index)}>X</a>
				</li>
			));
		  }  
		  
		  if(this.state.image.length > 0){
		
			imgThumblist =  this.state.image.map((url,index) => (
					<li className="thumb" key={indexlength+index}  >
						<span><img src={url}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					</li>
				));
			}
	  

		var	preview = (this.state.icon_preview) ? 
		<img className="img_class" src={this.state.icon_preview} alt="" /> :
		<img className="img_class" src={mediaUrl+'guideimages/'+this.state.old_tab_icon} alt="" />
		;
		

  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="guide" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				<a className="" href='/guide'><span class="close">X</span></a>	
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				
				<div className="form-group">
					<label>Title<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortab_title"></div>
				</div>

				{/* <div className="form-group">
					<label>Image (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					
					<div className="errorimage"></div>  
				</div>  */}

				{/* <div className="form-group">
					<label>Images (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="images" onChange={this.onChangeMultipleHandler} multiple/></span>
					</div>
					
					{oldImgThumblist}
					
					
					{imgThumblist}
					
					<div className="errorimage"></div>  
				</div>  */}

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
			   

			</div>
                  
			<div className="form-right">

				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} > * </span> : </label>
						{/* <textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  /> */}
					
						<CKEditor
						data={this.state.description}
						//data={templatecontent}
						onChange={this.onEditorChange}
						type="classic"
						/>
					<div className="errordescription"></div>
				</div>				
			</div>	
			</div>		

			<div className='form-row'>
			<div className="form-group">
					<label>Images (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="images" onChange={this.onChangeMultipleHandler} multiple/></span>
					</div>
					
					<ul className='listing-thump'>
					{oldImgThumblist}
					</ul>
					<ul className='listing-thump'>
					{imgThumblist}
					</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorimage"></div>  
				</div> 
			</div>

			

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (guideId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,guideId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));