/* eslint-disable */
import React, { Component } from 'react';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { GET_CHANGEPASSWORD } from '../../actions';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";


class Changepassword extends Component {

    constructor(props) {
        CheckAuth();
        super(props);
        this.state = {showload: false}
        this.state = {
            current_password: '',
            user_password: '',
            user_confirm_password: '',
            new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            errors: {},
            Loading:false
        }
        
        this.handlechangepassFormSubmit = this.handlechangepassFormSubmit.bind(this);
        this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
        this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
    }

    componentDidMount(){
        document.title = PageTitle('Change Password');
    }
      
   handleChangeTxt = (item, event) => {  
        this.setState({[item]:event.target.value }); 
    }
    
    new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }    
    handlechangepassFormSubmit = () => {
        
        if(this.validateForm()) {
            this.setState({Loading:true});
            this.setState(prevState => ({
                showload: !prevState.showload
            }));
            
            const formPayload = this.state;
            var qs = require('qs');
            var user_token = this.state.user_token; 
            var postObject = {
                "admin_id" : localStorage.getItem("admin_id"),
                "current_password": formPayload.current_password,
                "user_password": formPayload.user_password,
                "user_cpassword": formPayload.user_confirm_password
            }; 
            $(".change-btn").addClass('loading loading_data'); 
            this.props.getChangePassword(qs.stringify(postObject));
        }
    }
  validateForm() {        
        const {current_password,user_password,user_confirm_password} = this.state;
        let formIsValid = true;
        if (!current_password) {
            formIsValid = false;
             $('.currentpaswd').html('<span class="errorspan">Please enter old password</span>');
        }else if(current_password){
            $('.currentpaswd').html('');
        }

        if (!user_password) {
            formIsValid = false;
             $('.userpswd').html('<span class="errorspan">Please enter new password</span>');
        }else if(user_password){
            $('.userpswd').html('');
        }
        
        if (!user_confirm_password) {
            formIsValid = false;
            $('.usercpswd').html('<span class="errorspan">Please enter confirm password</span>');
        }else if(user_confirm_password){
            $('.usercpswd').html('');
        }
        if(user_password !== user_confirm_password){
            formIsValid = false;
             $('.usercpswd').html('<span class="errorspan">Password does not match</span>');
        }
        $(".change-btn").removeClass('loading loading_data');
        return formIsValid;
    }


    componentWillReceiveProps(Props) {
        if(Object.keys(Props.changepassworddata).length > 0){
                this.setState({Loading:false});
             if(Props.changepassworddata !== this.props.changepassworddata){
                        $(".change-btn").removeClass('loading loading_data');

                if(Props.changepassworddata[0].status === "success"){
                 

                     $('.success_message').html('<div class="status_sucess"><h3>'+Props.changepassworddata[0].message+'</h3></div>');
                     setTimeout(
                        function() {
                            $('.success_message').html('');
                        }
                        .bind(this),
                        3000
                        );
                }else{
                         $('.success_message').html('<div class="status_sucess"><h3>'+Props.changepassworddata[0].message+'</h3></div>');
                     setTimeout(
                        function() {
                            $('.success_message').html('');
                        }
                        .bind(this),
                        3000
                        );
                }
             }
        }
    }

render() {
    return (
        <div>  
         <Header />
         <MenuSidebar />
               <div className="content">
                   <div className="content-wrapper">
                   <div class="content-wrapper-before"></div>
			<div className="content-body leaa-admin-head card">
                        <div className="account-wrapper change-password-wrap">
                <div className="success_message"></div>
                            <div className="account-title-warpper">
                                <h2 className="title"><span>Change Password</span></h2>
                            </div>
                            <div>
                                 <form className="changepaswd" id="changepaswd" onSubmit={ e => { this.handlechangepassFormSubmit(this); e.preventDefault(); }}>
                                    <div className="form-group">
                                        <label>Old Password</label>
                                        <input type="password" className="form-control" name="current_password" id="current_password" placeholder="Enter old password"  value={this.state.current_password} onChange={this.handleChangeTxt.bind(this, 'current_password')}/>
                                        <div className="currentpaswd"></div>
                                    </div>
                                    <div className="form-group eye-pad">
                                        <label>New Password</label>
                                        <input type={this.state.new_pass_type}  name="user_password" placeholder="Enter new password" className="form-control" value={this.state.user_password} onChange={this.handleChangeTxt.bind(this, 'user_password')}/>
                                        <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                                        <div className="userpswd"></div>
                                    </div>
                                    <div className="form-group eye-pad">
                                        <label>Confirm Password</label>
                                        <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleChangeTxt.bind(this, 'user_confirm_password')}/>
                                        <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                                        <div className="usercpswd"></div>
                                    </div>
                                    <div className="account-submit cp-account-submit">  
                                     {/*   <button className="btn btn_orange btn_minwid change-btn animate-btn2" type="button" onClick={this.handlechangepassFormSubmit.bind(this)}>Submit
                                        </button>*/}

                                    <button className="btn btn_orange btn_minwid change-btn animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                    <span className="load-data"></span> 
                                    }Submit
                                    </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                   </div>
               </div>
               </div>
        </div>
    );
}
}

const mapStateTopProps = (state) => {
  return {
    changepassworddata: state.changepassword
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Changepassword));
