import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { apiUrl, categoryURL} from'../Config/Config';
import axios from 'axios';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const userid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      userid: userid,
      userlistview:'',
    };

   
   // this.props.getViewUserList(qs.stringify(postObject));  
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
		var qs = require('qs');
		var postObject = {             
			id   :this.state.userid
		};
	 axios.post(apiUrl+"user/view",qs.stringify(postObject)).then(res => {	   
	 // console.log(res.data.userlistview[0],'userlistview');
		if(res.data.status === "success"){
			this.setState({userlistview: res.data.userlistview}) 
         
		}
	   });
	   
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      /*if(Props.userlistview!==this.state.userlistview){
        if(Props.userlistview[0].status == "success"){
          this.setState({userlistview: Props.userlistview[0].userlistview[0]}) 
        }
      }*/
   }


  
  render() {
	  
	//  console.log(this.state.userlistview.mobileno,'mobile')

	var mobileno = ((this.state.userlistview.mobileno === null)||((this.state.userlistview.mobileno === ''))) ?  this.state.userlistview.mobileno = 'N/A' : this.state.userlistview.mobileno;

    var lastname = ((this.state.userlistview.lastname === null)||(this.state.userlistview.lastname === "")) ?  this.state.userlistview.lastname = 'N/A' : this.state.userlistview.lastname;

    if(this.state.userlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="user" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>User Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>First Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.firstname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Last Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{lastname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Mobile Number<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{mobileno}</p>
                          </div>                          
                        </div> 
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Domain name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.domain_name}</p>
                          </div>                          
                        </div>  
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>                       
                    </div>
                  </div>
                    
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

/*const mapStateTopProps = (state) => {
  return {
    userlistview: state.userlistview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewUserList: (userid) =>{
       dispatch({ type: GET_VIEWUSERLIST,userid });
    },
  }
}*/
export default (withRouter(View));