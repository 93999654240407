import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { cmscontentURL } from '../Config/Config';
import CKEditor from 'ckeditor4-react';

import {GET_EDITPACKAGE,GET_PACKAGEDETAIL  }  from '../../actions'; 

class Edit extends Component {
	
	
	constructor(props)
	{
		super(props);	
		const packageId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			type:'',
			package_id:'',
			package_content:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
            price:'',
			isHidden: false,
			packageId: packageId,
			Loading:false,
			admin_id: localStorage.getItem('admin_id')
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.onEditorChange = this.onEditorChange.bind(this);
	   this.props.getPackageDetail(packageId);
    }
	

	
	
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
     onEditorChange( evt ) {
    	console.log(evt.editor.getData())
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            package_content: evt.editor.getData()
        } );
		}, 1000);
     
    }
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Package Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }

  


 

 handleFormSubmit = () => {

			if(this.validateForm()){

				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
			
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					id : formPayload.package_id,
					type: formPayload.type,
					package_content:formPayload.package_content,
					price   : formPayload.price,
					status      :status,
					
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       

			    this.props.getEditPackage(formData,config);
			}
	}

	validateForm() {
		const {type,package_content,price} = this.state;
		let errors = 0;
	
		if (!type) {
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(type){
			$('.errorname').html('');
		}

		if (!price) {
			errors++;
			$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
		}else if(price){
			$('.errorprice').html('');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }

    componentWillReceiveProps(Props){


    	   if(Props.packagedetail !== this.props.packagedetail){
    		if(Object.keys(Props.packagedetail).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.packagedetail[0].status === "success"){

						const formdata = Props.packagedetail[0].packagelist;
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({type:formdata.type});
						this.setState({price:formdata.price});

						var callfunc = this;
						setTimeout(function () {
						callfunc.setState({package_content:formdata.package_content});
						}, 3000);

						//this.setState({logo_content:formdata.landing_content});
						this.setState({package_id: formdata.id});
					  
					}
    		}
    	}
		
         if(Props.packageedit !== this.props.packageedit){

    		if(Object.keys(Props.packageedit).length > 0){

    			this.setState({ Loading: false });

    			if(Props.packageedit[0].status === "success"){


    			const formdata = Props.packageedit[0].packagelist;
				if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				}

				this.setState({type:formdata.type});
				this.setState({price:formdata.price});
			    this.setState({package_id: formdata.id});
		        //this.setState({cms_image_preview: formdata.landing_content});
		        var callfunc = this;
				setTimeout(function () {
				callfunc.setState({package_content:formdata.package_content});
				}, 1000);

                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.packageedit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			this.props.history.push('/packages');
    		}else{
    			$('.success_message').html('<div class="status_sucess"><h3>'+ Props.packageedit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		 }
    		}
    	}   
      }

 
	
  render() {

	

	
  	const {selectedOption} = this.state;

	
	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="packages" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label> Type:</label>
				    <input type="text" name="type" onChange={this.handleInputChange} className="form-control" value={this.state.type} />
					<div className="errorname"></div>
				</div>

				<div className="form-group">					
  					<label>Package Description:</label>
 				     <CKEditor
					data={this.state.package_content}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
  					<div className="errorpackage_content"></div>
  				</div>
			</div>
			<div className="form-right">
				
				<div className="form-group">
					<label> Price:</label>
				    <input type="text" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price} />
					<div className="errorprice"></div>
				</div>
				

				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	packagedetail     : state.packagedetail,
  	packageedit       : state.packageedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	
    getPackageDetail: (packageId) =>{
       dispatch({ type: GET_PACKAGEDETAIL,packageId });
    },
    getEditPackage: (formPayload) =>{
       dispatch({ type: GET_EDITPACKAGE, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));