import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';


import {GET_ADDLOANCONFIG }  from '../../actions'; 

class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			
			above_revenue_amount:'',
			revenue_rating:'',
			
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue : 'active',
			Loading:false
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
	

    handleChange = selectedOption => {
		console.log(selectedOption, 'sel_opt');
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Rating Configuration Add');
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				const above_revenue_amount = formPayload.above_revenue_amount.replace('$', '');

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					amount:above_revenue_amount,
					revenue_rating:formPayload.revenue_rating,
					status: formPayload.selectedvalue

				};
				console.log(postObject, 'post_obj');
				//this.props.getAddCompany(qs.stringify(postObject));
				
				axios.post(apiUrl+"annualRevenueconfig/add",qs.stringify(postObject)).then(res => {
					this.setState({Loading:false});
					if(res.data.status === "success"){		
					
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({
							
							above_revenue_amount:'',
							revenue_rating:'',
						});
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/annualconfiguration'));
						}
						.bind(this),
						3000
						);
					 }else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					 }				 
					 
    	       });	
			}
	}
	handlePriceChange = (e) => {
		
		const inputValue = e.target.value;
		alert(inputValue)
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			above_revenue_amount: priceWithSymbol
		  });
		}
	  };
	validateForm() {
		const {above_revenue_amount,revenue_rating} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!revenue_rating) {
			formIsValid = false;
			$('.errorrevenue_rating').html('<span class="errorspan">Please fill the field</span>');
		}else if(revenue_rating){
			$('.errorrevenue_rating').html('');
		}
		
				
		if (!above_revenue_amount) {
			formIsValid = false;
			$('.errorabove_revenue_amount').html('<span class="errorspan">Please fill the field</span>');
		}else if(above_revenue_amount){
			if (/^\$?[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/.test(above_revenue_amount)) {

				$('.errorabove_revenue_amount').html('');
			  }else{
				formIsValid = false;
			$('.errorabove_revenue_amount').html('<span class="errorspan">Please enter the valid amount</span>');
			  }

			
		}

		



		this.setState({
			errors: errors
		});
		return formIsValid;
    }

	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="annualconfiguration" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Rating Configuration</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				
				
				<div className="form-group">
					<label>Annual Amount<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="number"  name="above_revenue_amount" onChange={this.handleInputChange} className="form-control" value={this.state.above_revenue_amount} />
					<div className="errorabove_revenue_amount"></div>
				</div>
				<div className="default-font">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>
			<div className="form-right">	
				
				<div className="form-group">					
					<label>Rating<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="text" name="revenue_rating" onChange={this.handleInputChange} className="form-control" value={this.state.revenue_rating} />
					<div className="errorrevenue_rating"></div>
				</div>

				
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


/*const mapStateTopProps = (state) => {
  return {
  	companyadd: state.companyadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddCompany: (formPayload) =>{
       dispatch({ type: GET_ADDLOANCONFIG, formPayload});
    }
  }
}*/

export default (withRouter(Add));