import { all } from "redux-saga/effects";
import { watchGetLoginData } from "./login";
import { watchGetChangePassword }from "./changepassword";
import { watchGetForgetPassword } from "./forgetpassword";
import { watchGetProfileDetails, watchGetUpdateProfile } from './profile';
import {  watchGetCompanyList,watchGetAddCompany, watchGetEditCompany, watchGetCompanyDetailList  } from './company';
import {watchGetUserList, watchActivateUser, watchExportUser,watchGetEditUser, watchGetUserDetailList,watchGetAddUser,watchGetImportUser,watchGetViewUserList} from './user';
import { watchGetFontList, watchGetFontName, watchGetAddFont, watchGetDeleteFont, watchGetEditFont, watchGetFontDetailList } from './font';

import { watchGetTemplateList, watchGetTemplateDetail, watchGetTemplateAdd, watchGetTemplateDelete, watchGetTemplateEdit } from './template';

import { watchGetAddCms, watchGetCmsList, watchGetEditCms, watchGetDeleteCms, watchGetCmsDetail,watchActivateCms } from './cms';

import { watchGetAddPackage, watchGetPackageList, watchGetEditPackage, watchGetDeletePackage, watchGetPackageDetail,watchActivatePackage } from './package';

export default function*() {
  yield all([
       watchGetLoginData(),
       
       watchGetChangePassword(),
       watchGetForgetPassword(),
       watchGetProfileDetails(),
       watchGetUpdateProfile(),
       watchGetCompanyList(),
	     watchGetAddCompany(),
       watchGetEditCompany(),
       watchGetEditFont(),
       watchGetFontList(),
       watchGetFontName(),
       watchGetAddFont(),
  	   watchGetUserList(),
  	   watchActivateUser(),
  	   watchExportUser(),
       watchGetDeleteFont(),
       watchGetEditUser(),
       watchGetUserDetailList(),
       watchGetAddUser(),
	   watchGetImportUser(),
       watchGetTemplateList(),
       watchGetTemplateDetail(),
       watchGetTemplateAdd(),
       watchGetTemplateDelete(),
       watchGetTemplateEdit(),
       watchGetViewUserList(),
       watchGetCompanyDetailList(),
       watchGetFontDetailList(),
       watchGetAddCms(),
       watchGetCmsList(),
       watchGetEditCms(),
       watchGetCmsDetail(),
       watchActivateCms(),
      
       watchGetAddPackage(),
       watchGetPackageList(),
       watchGetEditPackage(),
       watchGetPackageDetail(),
       watchActivatePackage(),
       

  ]);
}
