import axios from 'axios';
import { apiUrl} from'../Config/Config';

import $ from "jquery";


const getDropdownData = (field, urlIs) => {
	let data, data_arr_obj = [];
	axios.get(apiUrl+urlIs).then(resp => {
		if(resp.data.status == 'success'){

			if(field == 'fundrequired_merchant'){
				data = resp.data.loanconfigobj_mer;
			}

			if(field == 'fundrequired_enterprise'){
				data = resp.data.loanconfigobj_enterp;
			} 
			if(field == 'loanType'){
				data = resp.data.lt_dd_obj;
			} 
			
		}
		//console.log(data,"data123");
		
		// var data_arr_obj = [];
		let i;
		for(i=0; i<data.length; i++){				
			data_arr_obj[i] = { 
				value: data[i]['value'], 
				label:data[i]['label']
			};
		}
		//console.log(data_arr_obj,"data456");
				
	});
	//console.log(data_arr_obj,"data777");

	// var data = [
	// 	{ value: '10000', label: 'Underrr $10,000' },
	// 	{ value: '20000', label: '$10,000 - $20,000' },
	// 	{ value: '50000', label: '$20,000 - $50,000' },
	// 	{ value: '100000', label: '$50,000 - $100,000' },
	// 	{ value: '150000', label: '$100,000 - $150,000' },
	// 	{ value: '200000', label: '$150,000 - $200,000' },
	// 	{ value: '250000', label: '$200,000 - $250,000' },
	// 	{ value: '300000', label: '$250,000 - $300,000' }
	// ];
	return data_arr_obj;
}


export const loanType = getDropdownData('loanType','loantypes/dropdown_list');

export const fundRequired = getDropdownData('fundrequired_merchant','loanconfig/dropdown_list');

export const fundRequired_Enterp = getDropdownData('fundrequired_enterprise','loanconfig/dropdown_list');

export const loanAmountHelp = (val,e) =>{ 
	
	var txt = '';
	let obj = fundRequired.find((o, i) => {
		if (o.value == val) {
			txt = o.label; 
			return true; // stop searching
		}
	});	

	if(txt == ''){		
		let obj = fundRequired_Enterp.find((o, i) => {
			if (o.value == val) {
				txt = o.label; 
				return true; // stop searching
			}
		});	
	}	
	return txt;
}
export const businessTime = [
	{ value: '6', label: 'Less than 6 months' },
	{ value: '12', label: '6-12 months' },
	{ value: '24', label: '1 year' },
	{ value: '60', label: '2-3 years' },
	{ value: '90', label: '3-5 years' },
	{ value: '120', label: 'More than 5 years' }
  ];
export const loanTypeHelp = (val,e) =>{ //console.log(val,'annualrevenueHelp1')
	var txt = '';
	let obj = loanType.find((o, i) => {
		if (o.value == val) {
			txt = o.label; //console.log(o.label,'annualrevenueHelp2')
			return true; // stop searching
		}
	});		
	return txt;
}
export const bussinessTimeHelp = (val,e) =>{ 
	var txt = '';
	let obj = businessTime.find((o, i) => {
		if (o.value == val) {
			txt = o.label; 
			return true; // stop searching
		}
	});		 
	return txt;
}