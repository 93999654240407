/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { apiUrl, cmscontentURL} from'../Config/Config';

import { GET_SETTINGDATA, GET_UPDATESETTING, GET_FONTNAME } from '../../actions';
import Select from 'react-select';
import axios from 'axios';
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import Addinputsimple from '../Account/Addinputsimple';

class InviteFriend extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			invite_content_label:'',
			invite_content:'',
		};
		var qs = require('qs');
		
		var postobject = {
			admin_id:  localStorage.getItem("admin_id")
		};

		axios.post(apiUrl+"Invitefriend/details",qs.stringify(postobject)).then(res => {
				if(res.data.results){
					const formpayload = res.data.results;
					this.setState({invite_content_label:formpayload.invite_content_label});
					this.setState({invite_content:formpayload.invite_content});
	
					
				}
			});

		this.handleInputChange  = this.handleInputChange.bind(this);
		
    }

    handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      
    }
	
    componentDidMount() {
      document.title = PageTitle('Invite Friend');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	  let body = document.querySelectorAll("body")[0];
		if(localStorage.getItem('admin_id')){
			body.classList.remove("body-theme");
		}else{
			body.classList.add("body-theme");
		}

     }
	 


    handleFormSubmit = () => {
		if(this.validateForm()){
			this.setState({Loading:true});
			
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
				};
				
			const formPayload = this.state;

			var qs = require('qs');

			var postObject = {
				admin_id:  localStorage.getItem("admin_id"),
				invite_content_label: formPayload.invite_content_label,
				invite_content:formPayload.invite_content
			};

			let formData = new FormData();
			for(let k in postObject) {
			formData.append(k, postObject[k]);
			}

			axios.post(apiUrl+"Invitefriend/update",formData,config).then(res => {

				if(res.data.results){
					this.setState({Loading:false});
					const formpayload = res.data.results;
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
					this.setState({invite_content_label:formpayload.invite_content_label});
					this.setState({invite_content:formpayload.invite_content});
					scrollToTop();

					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
					);
				}
						
			});
		}
	}

	validateForm() {

		const {invite_content_label,invite_content} = this.state;
		let errors = 0;
      	//let formIsValid = true;
		// if (!settings_site_title) { 
		// 	errors++;
		// 	$('.errorsettings_site_title').html('<span class="errorspan">Please fill the field</span>');
		// }else if(settings_site_title){		
		// 	$('.errorsettings_site_title').html('');
		// }
					  
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

    componentWillReceiveProps(Props){

	}

 

  render() {
	 
		
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar currentpage="invitefriend" />		

	
	<div className="content">	
		<div className="content-wrapper">
		  <div class="content-wrapper-before"></div>
			<div className="content-body setting-main-head leaa-admin-head card">
			<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
				<div className="form-wrapper">
					<div className="success_message"></div>
					<div className="title">
						<h4>Invite Friend</h4>
					</div>
					<div className="form-row">
						<div className="form-left">
							<div className="form-group">					
								<label>Invite Label</label>
								<input type="text" name="invite_content_label" onChange={this.handleInputChange} className="form-control"value={this.state.invite_content_label} />
								<div className="invite_content_label"></div>
							</div>
							<div className="form-group">
								<label>Invite Content</label>								
								<textarea name="invite_content"  onChange={this.handleInputChange} className="form-control" value={this.state.invite_content}></textarea>
								<div className="errorsinvite_content"></div>
							</div>						
						</div>
						<div className="form-right">

							
						</div>				
					</div>
					<div className="btn-group" style={{textAlign:'left'}}>					
						<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
							<span className="load-data">Loading</span> 
							} Submit
						</button>	
					</div>	
				</div>
			</form>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default (withRouter(InviteFriend));