import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, categoryURL} from'../Config/Config';
import { PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';
import moment from"moment";
import {
  Table
} from "react-bootstrap"; 
import {loanAmountHelp} from "../Helpers/DropdownHelper";

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const transactionid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      transactionid: transactionid,
      transactionlistdata:'',
      transactionlistview:'',
    };

    var qs = require('qs');
    var postObject = {             
      id:transactionid
    };

    axios.post(apiUrl+"transactionhistory/view",qs.stringify(postObject)).then(res => {
      
      if(res.data.status === 'success'){
        this.setState({ transactionlistdata: res.data.transactionlistview});
        this.setState({ transactionlistview: res.data.transactionlistview[0]});
      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Transaction View');
   }

   listitems(){
    var transactionlistdata = this.state.transactionlistdata;
    if (transactionlistdata != "undefined" && transactionlistdata != null) {
    
      if (Object.keys(transactionlistdata).length > 0) {
        const helperlistDetails = transactionlistdata.map(
          (transactionlistdata, Index) => {
            let sno = Index+1;

            return (
              <tr>
              <td>{sno}</td>
              <td>{transactionlistdata.quote_gen_code}</td>             
              <td>{loanAmountHelp(transactionlistdata.fund_required)}</td>             
              {/* <td>${transactionlistdata.fund_required}</td>              */}
              <td>{(transactionlistdata.deduction_perc > 0) ? transactionlistdata.deduction_perc + "%" : 0}</td>             
              <td>${(transactionlistdata.price) ? transactionlistdata.price : 0}</td>             
              </tr>
            );
          }
        );
        return helperlistDetails;
      }

    }else {
    return (
      <tr>
        <td colspan="11" className="v-align-nr">
          No Result
        </td>
      </tr>
    );
    }
   }


  render() {
  if(this.state.transactionlistdata!==undefined){
    var transactionlistdata = this.state.transactionlistdata;
    var transactionlistview = this.state.transactionlistview;
  }

    

  let purchased_on = moment(transactionlistview.created_date).format("MM/DD/YYYY, h:m A");


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="transactionhistory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Transaction Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Order ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{transactionlistview.order_id}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Transaction ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{transactionlistview.stripe_transcation_id}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Purchased On<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{purchased_on}</p>
                        </div>
                      </div>
                      

                      <div className="overflow">
                      <Table striped bordered hover>
                        <thead>
                        <tr>
                        <th>S.No</th>
                        <th>Quotes</th>                        
                        <th>Loan Amount</th>
                        <th>Tax Percentage</th>
                        <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>{this.listitems()}</tbody>
                      </Table>
                      </div>

                      {transactionlistview.coupon_reduction_amount > 0 &&
                      <div className="view-details" style={{textAlign:'right'}}>
                          <p></p>
                          <p></p>
                            <p><b>Discount by coupon {(transactionlistview.coupon_reduction_perc > 0) ? parseInt(transactionlistview.coupon_reduction_perc) + "%" : ""}<span>:</span> </b></p>
                            <p>${(transactionlistview.coupon_reduction_amount > 0) ? transactionlistview.coupon_reduction_amount : 0}</p>
                      </div>
                      }

                      <div className="view-details" style={{textAlign:'right'}}>
                          <p></p>
                          <p></p>
                            <p><b>Total Amount<span>:</span> </b></p>
                            <p>${(transactionlistview.total_amount) ? transactionlistview.total_amount : 0}</p>
                        
                      </div>
                                      
                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );


    
  }
}

export default (withRouter(View));