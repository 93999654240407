import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, categoryURL} from'../Config/Config';
import { PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';
import moment from"moment";
import {
  Table
} from "react-bootstrap"; 
import {loanTypeHelp,bussinessTimeHelp,loanAmountHelp} from "../Helpers/DropdownHelper";


class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const transactionid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      transactionid: transactionid,
      transactionlistdata:'',
      leadsview:'',
    };

    var qs = require('qs');
    var postObject = {             
      id:transactionid
    };

    axios.post(apiUrl+"leads/view",qs.stringify(postObject)).then(res => {
      
      if(res.data.status === 'success'){
        this.setState({ transactionlistdata: res.data.leadsview});
        this.setState({ leadsview: res.data.leadsview[0]});
      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Transaction View');
   }

   listitems(){
    var transactionlistdata = this.state.transactionlistdata;
    if (transactionlistdata != "undefined" && transactionlistdata != null) {
    
      if (Object.keys(transactionlistdata).length > 0) {
        const helperlistDetails = transactionlistdata.map(
          (transactionlistdata, Index) => {
            let sno = Index+1;

            return (
              <tr>
              <td>{sno}</td>
              <td>{transactionlistdata.quote_gen_code}</td>             
              <td>{loanAmountHelp(transactionlistdata.fund_required)}</td>             
              {/* <td>${transactionlistdata.fund_required}</td>              */}
              <td>{(transactionlistdata.deduction_perc > 0) ? transactionlistdata.deduction_perc + "%" : 0}</td>             
              <td>${(transactionlistdata.price) ? transactionlistdata.price : 0}</td>             
              </tr>
            );
          }
        );
        return helperlistDetails;
      }

    }else {
    return (
      <tr>
        <td colspan="11" className="v-align-nr">
          No Result
        </td>
      </tr>
    );
    }
   }


  render() {
  if(this.state.transactionlistdata!==undefined){
    var transactionlistdata = this.state.transactionlistdata;
    var leadsview = this.state.leadsview;
  }
  var business_type = ((this.state.leadsview.business_type === null)||(this.state.leadsview.business_type === "")) ?  this.state.leadsview.business_type = 'N/A' : this.state.leadsview.business_type;
    

  let purchased_on = moment(leadsview.created_date).format("MM/DD/YYYY, h:m A");


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="leads" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Lead Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Business Category<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{leadsview.business_category}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Loan Type	<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{leadsview.loan_type}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Time in Business<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{bussinessTimeHelp(leadsview.business_time)}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Requested On<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{purchased_on}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Business Module<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{leadsview.business_module}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Business Type<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{business_type}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Loan Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{loanAmountHelp(leadsview.fund_required)}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Purchase ({leadsview.fund_perct}%) <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${leadsview.fund_perct_amt}</p>
                          
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Quote Rating <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{leadsview.quote_rating}</p>
                          
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Annual Revenue Rating <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{leadsview.annual_revenue_rating}</p>
                          
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Business Type Rating <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{leadsview.business_type_rating}</p>
                          
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Time in Business Rating <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{leadsview.time_in_business_rating}</p>
                          
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Credit Score rating <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{leadsview.credit_score_rating}</p>
                          
                        </div>
                      </div>

                      {/* <div className="overflow">
                      <Table striped bordered hover>
                        <thead>
                        <tr>
                        <th>S.No</th>
                        <th>Quotes</th>                        
                        <th>Loan Amount</th>
                        <th>Tax Percentage</th>
                        <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>{this.listitems()}</tbody>
                      </Table>
                      </div> */}

                      {/* {leadsview.coupon_reduction_amount > 0 &&
                      <div className="view-details" style={{textAlign:'right'}}>
                          <p></p>
                          <p></p>
                            <p><b>Discount by coupon {(leadsview.coupon_reduction_perc > 0) ? parseInt(leadsview.coupon_reduction_perc) + "%" : ""}<span>:</span> </b></p>
                            <p>${(leadsview.coupon_reduction_amount > 0) ? leadsview.coupon_reduction_amount : 0}</p>
                      </div>
                      }

                      <div className="view-details" style={{textAlign:'right'}}>
                          <p></p>
                          <p></p>
                            <p><b>Total Amount<span>:</span> </b></p>
                            <p>${(leadsview.total_amount) ? leadsview.total_amount : 0}</p>
                        
                      </div> */}
                                      
                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );


    
  }
}

export default (withRouter(View));