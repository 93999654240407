import { SET_EDITCMS } from '../actions';

const cmsedit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITCMS:
      return [...action.value];  
    default: return state;
  }
}

export default cmsedit;
