/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { PageTitle } from "../Helpers/SettingHelper";
// import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import $ from "jquery";
import parser from  'html-react-parser';

class Businesstime extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			Loading: false,
			bt_data: ''
		};
		var qs = require('qs');
		
		var postobject = {
			admin_id:  localStorage.getItem("admin_id")
		};

		axios.post(apiUrl+"businesstime/list",qs.stringify(postobject)).then(res => {
				if(res.data.bt_obj){					
					var bt_obj = res.data.bt_obj;						
					console.log(bt_obj, 'bt_obj');

					var action = sts = "-";					

					var bt_arr_obj = [];
					for(var i=0; i<bt_obj.length; i++){

						if(res.data.data == "yes"){
							var sts_icon = '<i style="cursor: pointer" id="'+ bt_obj[i]['id'] +'" data-status="A" class="fa fa-unlock changeStatus"></i>';
							var sts = 'Inactive';
							if(bt_obj[i]['status'] == 'A'){ 
								sts = 'Active'; 
								sts_icon = '<i style="cursor: pointer" id="'+ bt_obj[i]['id'] +'" data-status="I" class="fa fa-lock changeStatus"></i>';
							}
							action = parser(sts_icon + ' &nbsp; <i style="cursor: pointer" id="'+ bt_obj[i]['id'] +'" class="ft-trash-2 deleteRow"></i>');
						}
						
						bt_arr_obj[i] = { 
							id: bt_obj[i]['id'], 
							name:bt_obj[i]['name'], 
							sort_order: bt_obj[i]['sort_order'],
							status: sts,
							action: action 
						};
					}
					this.setState({bt_data:bt_arr_obj});
					console.log(bt_arr_obj, 'bt_arr_obj');
				}
			});
		this.handleInputChange  = this.handleInputChange.bind(this);
		
    }

    handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }
	
    componentDidMount() {
		document.title = PageTitle('Time in Business');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		let body = document.querySelectorAll("body")[0];
		if(localStorage.getItem('admin_id')){
			body.classList.remove("body-theme");
		}else{
			body.classList.add("body-theme");
		}
		$(document).ready(function () {	
			$('#bt-table').on('click', '.add-db', function () {
				var parent = $(this).parents('tr');
				var name = parent.find('#new_bt_name').val();
				var sort_order = parent.find('#new_bt_sort').val();

				var qs = require('qs');
				var postObject = {
					admin_id:  localStorage.getItem("admin_id"),
					name: name,
					sort_order: sort_order
				};

				if(name != "" && sort_order != ""){
					axios.post(apiUrl+"businesstime/add", qs.stringify(postObject)).then(res => {
						if(res.data.status == "success"){
							window.location.reload();
						}else{
							alert('Something went wrong');
						}
					});
				}else{
					alert('Please fill all the fields');
				}
			});

			
			$('#bt-table').on('click', '.deleteRow', function () {
				var id = $(this).attr('id');
				console.log(id, 'test');
				var confirm = window.confirm('Are you sure you want to delete this?');
    			if(confirm == true){
					var qs = require('qs');
					var postObject = {
						id:  id
					};
					axios.post(apiUrl+"businesstime/delete", qs.stringify(postObject)).then(res => {
						if(res.data.status == "success"){
							window.location.reload();
						}else{
							alert('Something went wrong');
						}
					});
				}
			});

			$('#bt-table').on('click', '.changeStatus', function () {
				var id = $(this).attr('id');
				var status = $(this).attr('data-status');
				console.log(id, 'test');
				var qs = require('qs');
				var postObject = {
					id:  id,
					status: status
				};
				axios.post(apiUrl+"businesstime/changestatus", qs.stringify(postObject)).then(res => {
					if(res.data.status == "success"){
						window.location.reload();
					}else{
						alert('Something went wrong');
					}
				});
			});
		});

     }
	 
   	saveCellData(oldValue,newValue,row,column){
		console.log(oldValue, 'oldValue'); 
		console.log(newValue, 'newValue'); 
		console.log(row, 'row'); 
		console.log(column, 'column'); 
		var qs = require('qs');
		axios.post(apiUrl+"businesstime/update", qs.stringify(row)).then(res => {
			if(res.data.status == "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>Something went wrong</h3></div>');
			}

			setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
			);
		});
  	}

	addNewRow(){
		console.log('agaggsgserts');
		var tableFields = '<tr><td></td><td><input type="text" id="new_bt_name" class="form-control"></td><td><input type="number" id="new_bt_sort" class="form-control"></td><td><button class="add-db"><i class="fa fa-plus"></i></button></td></tr>';
		$('.react-bootstrap-table').find('tbody').prepend(tableFields);
		$('#add_new_row').attr('disabled', true);
	}

	render() {

		const datas = this.state.bt_data;

		const columns = [
			{dataField: 'id', text: 'S.No'}, 
			{dataField: 'name', text: 'Name', align: 'left'}, 
			{dataField: 'sort_order', text: 'Sort Order', type: 'number', sort: true},
			{dataField: 'status', text: 'Status'},
			{dataField: 'action', text: 'Actions', editable: false}
		];

		let mystyle = {
		    color: "white",
		    backgroundColor: "#3E3E3C",
		    padding: "10px",		  
			minWidth: "49px",
			height: "37px",
			lineHeight: "0px",
			textTransform: "capitalize"
	
		};

		return (
		<div className="wrapper"> 
	
		<Header />
		<MenuSidebar currentpage="businesstime" />	

		
		<div className="content">	
			<div className="content-wrapper">
			<div className="content-wrapper-before"></div>
				<div className="content-body setting-main-head leaa-admin-head card">
				{/* <form className="settings"> */}
				<div className="form-wrapper" style={{textAlign: "right"}}>
				<div className="success_message"></div>
				<div className="title">
					<h4>Time in Business</h4>
				</div>
				
				<button id="add_new_row" href="#" style={mystyle} onClick={this.addNewRow.bind(this)} type="button" > <i class="fa fa-plus" aria-hidden="true"></i> Add</button>

				<BootstrapTable
				keyField="id"
				data={ datas }
				columns={ columns }
				cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: (oldValue, newValue, row, column) => { this.saveCellData(oldValue,newValue,row,column) } }) }
				deleteRow={ true }
				insertRow ={true}
				id='bt-table'
				/>

				</div>
				{/* </form> */}
				</div>	
			</div>	
		</div>
				
		</div>
		);
	}
}

export default (withRouter(Businesstime));