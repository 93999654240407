/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiUrl, baseUrl } from "../Config/Config";
import loginImg from "../../common/images/energy-logo-black.png"
import { GET_LOGINDATA} from '../../actions';

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {	
			user_name: '',
			password: '',		
			Loading: false,
			statusmessage:'',
			yeshiva_checked:false,
			kollel_checked:false,
			yeshiva:'',
			kollel:''
		};

    this.handleInputChange  = this.handleInputChange.bind(this);

	}

	componentDidMount(){
		let body = document.querySelectorAll("body")[0];
		body.classList.add("body-theme");
	}


  handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     
   }
	handleFormSubmit = () => {

			if(this.validateForm()){

				this.setState({Loading:true});
				const formPayload = this.state;

				var login_as = "admin";
				
				if(login_as  === 'admin'){

					var qs = require('qs');

					var postObject = {
					"user_name": formPayload.user_name,
					"password": formPayload.password,
					};
					$(".login_submit").addClass('loading loading_data');

					console.log(postObject)
					this.props.getLoginData(qs.stringify(postObject));

				}
				

			
				
			}

	}

	componentWillReceiveProps(Props){
		
		if (Props.login !== this.props.login) {
		this.setState({ Loading: false });
		$(".login_submit").removeClass('loading loading_data');
			if (Props.login[0].status === "success") {
				
				localStorage.setItem('loggedas','admin');
			    localStorage.setItem('admin_id',Props.login[0].te_admin_id);
			    localStorage.setItem('admin_name',Props.login[0].te_admin_name);
			    localStorage.setItem('admin_company_name',Props.login[0].admin_company_name);
			    localStorage.setItem('module_type',Props.login[0].module_type);
				this.props.history.push('/setting');
			}else{
				 this.setState({statusmessage: Props.login[0].message});
                     setTimeout(
                        function() {
                            this.setState({statusmessage:''})
                        }
                        .bind(this),
                        3000
                    );	
			}
		}

	

	}

	validateForm() {
		const {user_name,password} = this.state;
		let errors = {};
      	let formIsValid = true;
		$('.errorusername').html('');
		if (!user_name) {
		
			formIsValid = false;
			$('.errorusername').html('<span class="errorspan">Please check username</span>');
		}else if(user_name){		
			$('.errorusername').html('');
		}
		
		if (!password) {
			formIsValid = false;
			$('.errorpassword').html('<span class="errorspan">Please enter your password</span>');
		}else if(password){
			$('.errorpassword').html('');
		}
		this.setState({
			errors: errors
		});
		$(".login_submit").removeClass('loading loading_data');
		return formIsValid;
    }

render() {
	return (
		<div className="login-wrapper">
			<div className="account-wrapper">
				<div className="account-title-warpper">
				{/* <img src={loginImg} /> */}
				<h1>FUND</h1>
					<h2 className="title"><span>Admin panel</span></h2>
					<div><h2>{this.state.statusmessage}</h2></div>
				</div>
				<div className="login-form">
					 <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>

					    
						<div className="form-group">
							{/*<label>Username</label>*/}
									<input type="text" className="form-control" name="user_name" id="user_name" value={this.state.user_name} onChange={this.handleInputChange}placeholder="Your Username" />
							<div className="errorusername"></div>
						</div>
						<div className="form-group">
							{/*<label>Password</label>*/}
							<input type="password" className="form-control" name="password" id="password" value={this.state.password} onChange={this.handleInputChange} placeholder="Your Password" autoComplete="on"/>
							<div className="errorpassword"></div>
						</div>
						<div className="account-forgot-psw">
							<a href="/forget_password">Forgot Password?</a>
						</div>
						<div className="account-submit">
						{/*<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="button" onClick={this.handleFormSubmit.bind(this)}>
							{this.state.loading ===true &&
							<span class="load-data">Loading</span> 
							}
							Login
						</button>*/}
						<button className="btn btn_orange btn_minwid login_submit animate-btn2 theme-btn" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Loading</span>:<span>Login</span>
                                        } 
								</button>	
						</div>
					</form>
				</div>
			</div>
		</div>

    );


}
}


const mapStateTopProps = (state) => {
  return {
	login: state.login,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Login));

