import React, { Component } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';


import {GET_ADDLOANCONFIG }  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const LoanConfigId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			label:'',			
			amount:'',
			percentage:'',
			amount_type:'',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue : '',
			selectedPlanOption: "",
			selectedPlanvalue: "",
			selectedrequirmentvalue: "",
			c_loanconfig_id: LoanConfigId,
			getrequireddocument:[],
			Loading:false
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

		var qs = require('qs');
		var loanconfigId = this.state.c_loanconfig_id;

		axios.get(apiUrl+"loantypes/listdetail?id="+loanconfigId).then(res => {
			const formdata = res.data.documentlist;
			console.log(formdata, 'form---data');
			
			if(formdata.category != ''){
				this.setState({selectedPlanOption:{value: formdata.category, label: formdata.category}});
			}
			if(formdata.status === 'A'){
				this.setState({selectedvalue:'active',selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedvalue:'inactive',selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			this.setState({name:formdata.lt_name});				
			this.setState({selectedPlanvalue: formdata.category});
			this.setState({sort_order: formdata.lt_sort_order});
			this.setState({selectedrequirmentid:formdata.requirements});
			this.setState({selectedrequirmentvalue:formdata.requirements});
			this.setState({bank_statement_count:formdata.bank_statement_count});
			
		}); 
    }
	

	handleChange = selectedOption => {
		
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   	};
	handlecatChange = selOption => {
		this.setState({selectedPlanOption:selOption})
			this.setState({  selectedPlanvalue : selOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Loan Configuration Add');
		var admin_id = localStorage.getItem('admin_id');
	axios.get(apiUrl+'loantypes/getrequireddocument?admin_id='+admin_id)
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					getrequireddocument:res.data.getrequireddocument
				});
			
			}else{
			}
		});
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var selectedrequirmentidsd='';

				var qs = require('qs');
			
				const selectedrequirmentid = formPayload.selectedrequirmentid;
				var elements=[];
				let catList  = ''
				if(typeof selectedrequirmentid === 'object' && selectedrequirmentid!=='' && typeof selectedrequirmentid!==undefined && selectedrequirmentid!==null) {
					catList = selectedrequirmentid.map((number) =>
					elements.push(number.value)
				);
				selectedrequirmentidsd=elements.toString();

				}else{
					selectedrequirmentidsd=selectedrequirmentid;

				}
				var postObject = {
		
					admin_id:  localStorage.getItem("admin_id"),
					name: formPayload.name,
					requirements: selectedrequirmentidsd,
					category: formPayload.selectedPlanvalue,
					sort_order: formPayload.sort_order,
					status: formPayload.selectedvalue,
					id : formPayload.c_loanconfig_id,
					bank_statement_count : formPayload.bank_statement_count

				};
				// console.log(postObject, 'post_obj');
				//this.props.getAddCompany(qs.stringify(postObject));
				
				axios.post(apiUrl+"loantypes/update",qs.stringify(postObject)).then(res => {
					this.setState({Loading:false});
					if(res.data.status === "success"){		
					
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					 }else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					 }				 
					 
    	       });	
			}
	}
	handlePriceChange = (e) => {
		const inputValue = e.target.value;
	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			amount: priceWithSymbol
		  });
		}
	  };
	  validateForm() {
		const {name,selectedrequirmentid,sort_order,selectedPlanvalue} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!name) {
			formIsValid = false;
			$('.errorlabel').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorlabel').html('');
		}
		if (!selectedrequirmentid) {
			formIsValid = false;
			$('.errorselectedrequirmentid').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedrequirmentid){
			$('.errorselectedrequirmentid').html('');
		}
		if (!sort_order) {
			formIsValid = false;
			$('.errorsort_order').html('<span class="errorspan">Please fill the field</span>');
		}else if(sort_order){
			$('.errorsort_order').html('');
		}
		

		if (!selectedPlanvalue) {
			formIsValid = false;
			$('.errorcategory').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedPlanvalue){
			$('.errorcategory').html('');
		}



		this.setState({
			errors: errors
		});
		return formIsValid;
    }

	handleClickSelecetCategory = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
	
		this.setState({
				category_name: keys,	
		});
	
	}
	handleProductCategoryChange = (event) => {
		console.log(event,'event')
		this.setState({selectedrequirmentid : event}, function() {
		   const user_list = this.state.selectedrequirmentid;
		 
		});
	}
  render() {
  	  const {selectedOption,selectedPlanOption,selectedrequirmentvalue,getrequireddocument} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="loantype" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details
</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Loan<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
					<div className="errorlabel"></div>
				</div>
				<div className="form-group">	
					<label>Required Documents </label>
					
					<Multiselect
						displayValue="label"
						onRemove={this.handleProductCategoryChange}

						onSelect={this.handleProductCategoryChange}
						value={this.state.selectedrequirmentid}
						selectedValues={selectedrequirmentvalue?getrequireddocument.filter(obj => selectedrequirmentvalue.includes(obj.value)):{ }}
						options={this.state.getrequireddocument}
						/>
					<div className="errorselectedrequirmentid"></div>
				 </div>
				 <div className="form-group">	
					<label>No of Bank statement required </label>

					<input type="text" name="bank_statement_count" onChange={this.handleInputChange} className="form-control" value={this.state.bank_statement_count} />
					<div className="errorbank_statement_count"></div>
				 </div>
				
			</div>
			
			<div className="form-right">	
			<div className="form-group">					
					<label> Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedPlanOption?selectedPlanOption:""}
                            options={lang.common.loantype_option} 
                            onChange={this.handlecatChange.bind(this)}
                            />
							<div className="errorcategory"></div>
	           </div> 
			   <div className="form-group">
					<label>Priority<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="sort_order" onChange={this.handleInputChange} className="form-control" value={this.state.sort_order} />
					<div className="errorsort_order"></div>
				</div>

				<div className="default-font">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


/*const mapStateTopProps = (state) => {
  return {
  	companyadd: state.companyadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddCompany: (formPayload) =>{
       dispatch({ type: GET_ADDLOANCONFIG, formPayload});
    }
  }
}*/

export default (withRouter(Edit));