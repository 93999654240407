import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, categoryURL} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from"moment";

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      transactionlist:'',
      domainlist:[],
      activePage:1,
      sno_index:0,
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
     this.transactionlistload()
   }
transactionlistload(postobject=''){
  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  if(postobject!=''){
    var postobject=postobject
  }else{
    var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      }; 
  }
  

  axios.post(apiUrl+"transactionhistory/list",qs.stringify(postobject)).then(res => {
    if(res.data.status === 'success'){
     
        this.setState({  transactionlist: res.data.transactionlist, totalRecord: res.data.records_count}, function() {
        
          this.transactionlist();
        });

    }
  }); 
}
   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var sno_index = pageNumber - 1;
     this.setState({sno_index: sno_index});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
     
     this.transactionlistload(postobject)

   }

   handleInputChange(event) {
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   var postobject = {
    activePage: 1,
    search_all: value,
    admin_id: admin_id,
    loggedas:loggedas
  };
   this.transactionlistload(postobject)
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  this.transactionlistload(postObject)


} 



   componentWillReceiveProps(Props){

     
   }

transactionlist() {
  let catImg = '';
  let preview = '';
    var transactionlist = this.state.transactionlist;
    var sno_index = this.state.sno_index;
    if (transactionlist != "undefined" && transactionlist != null) {
   
        if (Object.keys(transactionlist).length > 0) {
          const helperlistDetails = transactionlist.map(
            (transactionlist, Index) => {
              if(sno_index > 0){ Index = (sno_index * 10) + Index; }              
              let sno = Index+1;
              let purchased_on = moment(transactionlist.created_date).format("MM/DD/YYYY, h:m A");
              let trans_for = '';
              if(transactionlist.trans_for == "broker"){trans_for = "Broker"}else if(transactionlist.trans_for == "lender"){trans_for = "Lender"}
              return (
                <tr key={transactionlist.quote_transaction_id}>
      					<td>{sno}</td>
      					<td>{transactionlist.order_id}</td>
      					{/* <td>{transactionlist.stripe_transcation_id}</td> */}
      					<td>{trans_for}</td>
      					<td>${transactionlist.total_amount}</td>
      					<td>{transactionlist.total_items}</td>
      					<td>{purchased_on}</td>
                <td className="actiongroup" >
                    
                    
                     <Link to={{ pathname: '/transactionhistory-view/'+transactionlist.quote_transaction_id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                   
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
    
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="transactionhistory" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Transaction History</h3>
                </div>
                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Order ID</th>
                  {/* <th>Transaction ID</th> */}
                  <th>Type</th>
                  <th> Amount</th>
                  <th>Total Items</th>
                  <th>Purchased On</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.transactionlist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (withRouter(List));