import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

// import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      splist:''
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"Brokersplitpayment/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  splist: res.data.splist, totalRecord: res.data.records_count}, function() {
              this.splist();
            });
  
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getsplist(qs.stringify(postobject))
    axios.post(apiUrl+"Brokersplitpayment/list",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
        console.log(res.data.splist, 'splist');
          this.setState({  splist: res.data.splist, totalRecord: res.data.records_count}, function() {
            this.splist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"Brokersplitpayment/list",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  splist: res.data.splist, totalRecord: res.data.records_count}, function() {
          this.splist();
        });

    }
  }); 

} 



splist() {
    var splist = this.state.splist;
    const cred_date = Moment(splist.created_date).format('MM/DD/YYYY, hh:mm A');
    if (splist != "undefined" && splist != null) {
      console.log(splist);
    
      if (splist.length > 0) {

        if (Object.keys(splist).length > 0) {
          const helperlistDetails = splist.map(
            (splist, Index) => {
              let sno = Index+1;
              

             
              return (
                <tr key={splist.sp_id}>
      					<td>{sno}</td>
                <td>{splist.sp_quote_gen_code}</td>
                <td>${splist.fund_required}</td>
      					<td>${(splist.sp_amount ? splist.sp_amount : 0)}</td>
      					<td>{(splist.split_perc ? splist.split_perc : 0)}%</td>
      					<td>{splist.nameIs} ({splist.trans_for})</td>
      					<td>{cred_date}</td>
              
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Brokersplitpayment" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Broker Split Payment</h3>
                </div>
               

                {/* <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> */}
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
                  <th>Quote ID</th>
			            <th>Loan Amount</th>
			            <th>Commission Amount</th>
			            <th>Commission Percentage</th>
			            <th>Commission From</th>
			            <th>Credited On</th>
                   </tr>
                  </thead>
                  <tbody>{this.splist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (withRouter(List));