import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";

import CKEditor from 'ckeditor4-react';


import { GET_TEMPLATEADD }  from '../../actions'; 

class Add extends Component {
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			email_name:'',
			email_content:'',	
			Loading:false,
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
    }
	

   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });
    }

    onEditorChange( evt ) {
    	console.log(evt.editor.getData())
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            email_content: evt.editor.getData()
        } );
		}, 1000);
     
    }

	componentDidMount() {
		document.title = PageTitle('Template Add');		
    }



	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});


				const formPayload = this.state;
				var qs = require('qs');
			
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					email_name   : formPayload.email_name,
					email_content: formPayload.email_content,
				};
				
				this.props.getTemplateAdd(qs.stringify(postObject));
			}
	}

	validateForm() {

		const {email_name,email_content} = this.state;

		let errors = 0;
	
		if (!email_name) {
			errors++;
			$('.erroremail_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(email_name){
			$('.erroremail_name').html('');
		}
		
		if (!email_content) {
			errors++;
			$('.erroremail_content').html('<span class="errorspan">Please fill the field</span>');
		}else if(email_content){
			$('.erroremail_content').html('');
		}



		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

    	 	if(Props.templateadd !== this.props.templateadd){
    		if(Object.keys(Props.templateadd).length > 0){
				this.setState({Loading:false});
					if(Props.templateadd[0].status === "success"){
		            	$('.success_message').html('<div class="status_sucess"><h3>'+Props.templateadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							this.setState({email_name:'',email_content:''})
							$('.success_message').html(this.props.history.push('/templates'));
							
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.templateadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
      }

  render() {

    return (
      <div className="wrapper"> 
	  <Header />
      <MenuSidebar currentpage="templates" />  
	 <div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Template</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row mail-template-sec">
			<div className="form-left mail-template-inner">
		    	<div className="form-group">
					<label>Template Name<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="email_name" onChange={this.handleInputChange} className="form-control" value={this.state.email_name} autoComplete="off" />
					<div className="erroremail_name"></div>
				</div>
				<div className="form-group">
					<label>Template content<span class="required" style={{ color: "red" }} > * </span></label>
					<CKEditor
					data={this.state.email_content}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
					<div className="erroremail_content"></div>
				</div>
				
			</div>				

			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	templateadd     : state.templateadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTemplateAdd: (formPayload) =>{
       dispatch({ type: GET_TEMPLATEADD, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));