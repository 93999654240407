import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, categoryURL} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from"moment";
import {loanTypeHelp,bussinessTimeHelp,loanAmountHelp} from "../Helpers/DropdownHelper";
import Select from 'react-select';
import { lang } from '../Helpers/lang';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      leadlist:'',
      domainlist:[],
      activePage:1,
      sno_index:0,
      selectedCateOption:'',
      selectedCatevalue:'',
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
     this.leadlistload()
   }
leadlistload(postobject=''){
  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  if(postobject!=''){
    var postobject=postobject
  }else{
    var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      }; 
  }
  

  axios.post(apiUrl+"leads/list",qs.stringify(postobject)).then(res => {
    if(res.data.status === 'success'){
     
        this.setState({  leadlist: res.data.leadlist, totalRecord: res.data.records_count}, function() {
        
          this.leadlist();
        });

    }else{
      this.setState({  leadlist:null, totalRecord: 0}, function() {
        
        this.leadlist();
      });
    }
  }); 
}
   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var sno_index = pageNumber - 1;
     this.setState({sno_index: sno_index});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
     
     this.leadlistload(postobject)

   }

   handleInputChange(event) {
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   var postobject = {
    activePage: 1,
    search_all: value,
    admin_id: admin_id,
    leads_type: this.state.selectedCatevalue,
    loggedas:loggedas
  };
   this.leadlistload(postobject)
 }
 handleChange = (selOption) => {       	
  this.setState({selectedCateOption:selOption})
  this.setState({  selectedCatevalue : selOption.value});
     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: 1,
       leads_type : selOption.value,
       admin_id: admin_id,
       loggedas:loggedas
     };
     
     this.leadlistload(postobject)
};
 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    leads_type: this.state.selectedCatevalue,
    loggedas: loggedas         
  };
  this.leadlistload(postObject)

} 



   componentWillReceiveProps(Props){

   }

leadlist() {
  let catImg = '';
  let preview = '';
    var leadlist = this.state.leadlist;
    var sno_index = this.state.sno_index;
    if (leadlist != "undefined" && leadlist != null) {
   
        if (Object.keys(leadlist).length > 0) {
          const helperlistDetails = leadlist.map(
            (leadlist, Index) => {
              if(sno_index > 0){ Index = (sno_index * 10) + Index; }              
              let sno = Index+1;
              let purchased_on = moment(leadlist.created_date).format("MM/DD/YYYY, h:m A");
              let trans_for = '';
              if(leadlist.trans_for == "broker"){trans_for = "Broker"}else if(leadlist.trans_for == "lender"){trans_for = "Lender"}
              return (
                <tr key={leadlist.quote_id}>
      					<td>{sno}</td>
      					<td>{leadlist.business_category}</td>
      					<td>{leadlist.loan_type}</td>
      				
      					{/* <td>{loanTypeHelp(leadlist.loan_type)}</td> */}
      					{/* <td>{leadlist.stripe_transcation_id}</td> */}
      					<td>{bussinessTimeHelp(leadlist.business_time)}</td>
      					{/* <td>${loanAmountHelp(leadlist.fund_required)}</td> */}
      					<td>{purchased_on}</td>
                <td className="actiongroup" >
                    
                    
                     <Link to={{ pathname: '/lead-view/'+leadlist.quote_id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                   
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
    
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="leads" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Leads</h3>
                </div>
                <div className="search-list">
                   <form className="edit listing" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                   <div class="input_search">
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                  </div>
                  <div className="search-inner search_select">
                  <Select name="category" options={lang.common.leadsType_option} 
                          
                          onChange={this.handleChange}
                            />
                             </div>
                 </form>
                
                   
                   
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Business Category</th>
                  <th>Loan Type</th>
                  <th>Time in Business</th>
                  {/* <th> Loan Amount</th> */}
                  {/* <th>Total Items</th>*/}
                  <th>Requested On</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.leadlist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (withRouter(List));