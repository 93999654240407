import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $, { parseJSON } from 'jquery';
import Select from 'react-select';
import CKEditor from 'ckeditor4-react';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { mediaUrl,apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const flagId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            flagId: flagId,
			flag_name:'',
			description:'',
			title:'',
			content:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			image_name: [],
			image_preview: [],
			image:[],
			old_image:[],
			image_fileExtension:[],
			allImgArray : [],
			apply_on_maintab : false,
			apply_on_innertab : false,
			old_tab_icon : '',
			flag_color:'',
		};
		// this.props.getCategoryDetailList(tabId);
		var qs = require('qs');
		var catid = this.state.flagId;

		axios.get(apiUrl+"tabmanagement/flaglistdetail?id="+catid).then(res => {
			
			this.setState({flag_name:res.data.flaglist.flag_name,flag_color:res.data.flaglist.flags_color});
			if(res.data.flaglist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeMultipleHandler = this.onChangeMultipleHandler.bind(this);

    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'apply_on_maintab'){
      	this.setState({apply_on_maintab: !this.state.apply_on_maintab});
      } 
	  if(name === 'apply_on_innertab'){
			this.setState({apply_on_innertab: !this.state.apply_on_innertab});
		} 	


		
    }

	componentDidMount() {
		document.title = PageTitle('Tab Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					flag_name: formPayload.flag_name,
					id : this.state.flagId,
					flags_color : formPayload.flag_color,
					status:  status,
				
				};
				let formData = new FormData();
				
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				
				axios.post(apiUrl+"tabmanagement/flagedit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/flags'));
								}
								.bind(this),
								3000
								);
								
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {flag_name}   = this.state;
		let errors = {};
      	let formIsValid = true;
		if (!flag_name) {
			formIsValid = false;
			$('.errorflag_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(flag_name){
			$('.errorflag_name').html('');
		}
		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }


	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			icon_name: file.name,
			icon_preview: reader.result,
			icon: file,
			icon_fileExtension:extension,
			
		  });
		};
		reader.readAsDataURL(file);
		
	  } 

	  onChangeMultipleHandler= e => {
		this.fileObj = [];
		this.fileNameArray = this.state.image_name;
		this.fileArray = this.state.image;
		this.extensionArray = this.state.image_fileExtension;
		this.image_preview = this.state.image_preview;
		this.allImgArray = this.state.allImgArray;
		this.fileObj.push(e.target.files);
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileNameArray.push(this.fileObj[0][i].name)
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			this.allImgArray.push(this.fileObj[0][i])
			this.extensionArray.push(this.fileObj[0][i].name.split('.').pop());
			
		}
		
		this.setState({
			image_name: this.fileNameArray,
			image_preview: this.image_preview,
			image: this.fileArray,
			image_fileExtension:this.extensionArray,
			
		  });
		
	  }
	  
	removeImagebyindex = (indexvalue) => {
		var list = this.state.image;
		var allImgArray = this.state.allImgArray;
		
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
		allImgArray.splice(indexvalue,1)

	
		this.setState({ image: list , allImgArray : allImgArray })
		
	}

	OldremoveImagebyindex = (indexvalue) => {
		var list = this.state.old_image;
		console.log(list,'list')
		list.splice(indexvalue, 1);
		console.log(list,'list')

		this.setState({ old_image: list })
		
	}

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData())
    	// var callfunc = this;
    	// setTimeout(function () {
		// callfunc.setState( {
        //     terms_and_condition: evt.editor.getData()
        // } );
		// }, 1000);
		localStorage.setItem('tab_content',evt.editor.getData())
     
    }

  render() {

  	  const {selectedOption} = this.state;
	 
		var oldImgThumblist;
		var imgThumblist ;
		let indexlength =  this.state.image.length;
		if(this.state.old_image.length > 0){
			oldImgThumblist =  this.state.old_image.map((url,index) => (
				<li className="thumb" key={indexlength+index}  >
					<span><img src={mediaUrl+'gallery/'+url}  alt="..." /></span>
					<a href="javascript:void(0);" onClick={this.OldremoveImagebyindex.bind(this,index)}>X</a>
				</li>
			));
		  }  
		  
		  if(this.state.image.length > 0){
		
			imgThumblist =  this.state.image.map((url,index) => (
					<li className="thumb" key={indexlength+index}  >
						<span><img src={url}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					</li>
				));
			}
	  

		var	preview = (this.state.icon_preview) ? 
		<img className="img_class" src={this.state.icon_preview} alt="" /> :
		<img className="img_class" src={mediaUrl+'tabicons/'+this.state.old_tab_icon} alt="" />
		;
		

  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="flags" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				<a className="" href='/flags'><span class="close">X</span></a>	
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Flag Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="flag_name" onChange={this.handleInputChange} className="form-control" value={this.state.flag_name} />
					<div className="errorflag_name"></div>
				</div>

			
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
			</div>
                  
			<div className="form-right">

			<div className="form-group">
							<label>Flag color:</label><br></br>
							<input type="color" id="head" name="flag_color" className="form-control"  value={this.state.flag_color} onChange={this.handleInputChange} />
							<div className="error_flag_color"></div>
					</div>

			

				
			</div>	
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (tabId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,tabId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));