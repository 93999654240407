import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const LoanConfigId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			label:'',			
			amount:'',
			percentage:'',
			amount_type:'merchant',
			selectedOption:{ value: 'merchant', label: 'Merchant'},
			c_loanconfig_id: LoanConfigId,
			Loading:false
		};
	
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");
		
		var qs = require('qs');
		var loanconfigId = this.state.c_loanconfig_id;

		axios.get(apiUrl+"loanconfig/listdetail?id="+loanconfigId).then(res => {
			const formdata = res.data.loanconfiglist;
			console.log(formdata, 'form---data');
			if(formdata.amount_type === 'Merchant'){
				this.setState({selectedOption:{value: 'merchant', label: 'Merchant'}});
			}else{
				this.setState({selectedOption:{value: 'enterprises', label: 'Enterprises'}});


			}
			const amount = formdata.amount_is!=0? `$${formdata.amount_is}`:'';
					
			this.setState({label:formdata.label});				
			this.setState({amount: amount});
			this.setState({percentage: formdata.percentage_is});
			this.setState({amount_type: formdata.amount_type});
		}); 

    }
	

    // handleChange = (v, action) => {
	// 	console.log(v,"name_type");
	// 	console.log(action,"action");
    //     this.setState({  [action] : v.value});
    // };

	handleChange = selectedOption => {
        this.setState({selectedOption});
    };

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Loan Configuration Edit');
    }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				let amount_type;
				const amount = formPayload.amount.replace('$', '');
				amount_type =formPayload.selectedOption.value;
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					label: formPayload.label,					
					amount:amount,
					percentage:formPayload.percentage,
					amount_type:amount_type,
					loanconfigId : formPayload.c_loanconfig_id
				};
				console.log(postObject, 'post_obj')			;
				
				axios.post(apiUrl+"loanconfig/edit",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.loanconfiglist;
						if(formpayload.amount_type === 'Merchant'){
							this.setState({selectedOption:{value: 'merchant', label: 'Merchant'}});
						}else{
							this.setState({selectedOption:{value: 'enterprises', label: 'Enterprises'}});
			
						}
						const amount = formpayload.amount_is!=0? `$${formpayload.amount_is}`:'';
						this.setState({label:formpayload.label});				
						this.setState({amount: amount});
						this.setState({percentage: formpayload.percentage_is});
						this.setState({amount_type: formpayload.amount_type});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}
	handlePriceChange = (e) => {
		const inputValue = e.target.value;
	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			amount: priceWithSymbol
		  });
		}
	  };
	validateForm() {
		const {label,amount,percentage,amount_type} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!label) {
			formIsValid = false;
			$('.errorlabel').html('<span class="errorspan">Please fill the field</span>');
		}else if(label){
			$('.errorlabel').html('');
		}
		
		if (!amount) {
			formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		}else if(amount){
			if (/^\$?[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/.test(amount)) {

				$('.erroramount').html('');
			  }else{
				formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please enter the valid amount</span>');
			  }

			
		}	
		

		// if (!admin_contact) {
		// 	formIsValid = false;
		// 	$('.erroradmin_contact').html('<span class="errorspan">Please fill the field</span>');
		// }else if(admin_contact){
		// 	$('.erroradmin_contact').html('');
		// }

		if (!percentage) {
			formIsValid = false;
			$('.errorpercentage').html('<span class="errorspan">Please fill the field</span>');
		}else if(percentage){
			$('.errorpercentage').html('');
		}



		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(){

		
    }
	
  render() {
  	  const {selectedOption} = this.state;
	  console.log(selectedOption, 'sel_opt');
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="loanconfig" />  

	
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
		<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Label<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="label" onChange={this.handleInputChange} className="form-control" value={this.state.label} />
					<div className="errorlabel"></div>
				</div>
				
				
				<div className="form-group">
					<label>Tax Percentage<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="number" pattern="[0-9]*" name="percentage" onChange={this.handleInputChange} className="form-control" value={this.state.percentage} />
					<div className="errorpercentage"></div>
				</div>
				
			</div>	
			<div className="form-right">
				<div className="form-group">					
					<label>Amount<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="text" name="amount" onChange={this.handlePriceChange} className="form-control" value={this.state.amount} />
					<div className="erroramount"></div>
				</div>

				<div className="form-group">					
					<label>Amount Type:</label>
						<Select name="amount_type"
						value={selectedOption?selectedOption:{ value: 'merchant', label: 'Merchant'}}
							options={lang.common.amounttype_option} 
							onChange={(values)=>this.handleChange(values, "amount_type")}
							/>
					<div className=""></div>
				</div>
			</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));