import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import CKEditor from 'ckeditor4-react';


import {GET_ADDCMS }  from '../../actions'; 

	
	
const initialState = {
			name:'',
			logo_content:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedsizevalue: '',
            selectedsizeOption:{ value: '350x350', label: '350x350'},
			cms_image_name: '',
			cms_image_preview: '',
			image: [],
			Loading:false,
			fileName: '',
			thumbDefault: null,
			admin_id: localStorage.getItem('admin_id')
		};
		
class Add extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.onChangeHandler = this.onChangeHandler.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleReset = this.handleReset.bind(this);
    }
	handleReset() {
         this.setState(initialState);
		 this.fileObj = [];
		 this.fileArray = [];
		 this.imageArray = [];
    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

   handleInputChange(event) {

      const {name, value} = event.target;    
      console.log()
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Cms Add');
    }


  onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        cms_image_name: file.name,
        cms_image_preview: reader.result,
        image: file
      });
    };
    reader.readAsDataURL(file);
  }



onThumbChanged = (e) => {
    this.setState({
      thumbDefault: e.currentTarget.value
      });
  }
  

	
	 handleFormSubmit = () => {


			if(this.validateForm()){
				
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
			
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					name: formPayload.name,
					logo_content:formPayload.logo_content,
					cms_image        : formPayload.image,
					status      :status,
					thumbDefault :  formPayload.thumbDefault
					
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				
				
				this.props.getAddCms(formData,config);
				//this.props.getAddCar(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {name,logo_content,cms_image_preview} = this.state;
		let errors = 0;
	
		if (!name) {
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }

    componentWillReceiveProps(Props){

    	 	if(Props.cmsadd !== this.props.cmsadd){
    		if(Object.keys(Props.cmsadd).length > 0){
				this.setState({Loading:false});

					if(Props.cmsadd[0].status === "success"){

							$('.success_message').html('<div class="status_sucess"><h3>'+Props.cmsadd[0].message+'</h3></div>');
							scrollToTop();
							setTimeout(
							function() {
							$('.success_message').html('');
							this.handleReset();
							}
							.bind(this),
							3000
							);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+Props.cmsadd[0].message+'</h3></div>');
							scrollToTop();
							setTimeout(
							function() {
							$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}

    		}
    	}

     }
	  
	removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		var thumbDefault = this.state.thumbDefault;
		if(indexvalue <= thumbDefault){
			let thum = thumbDefault-1;
			 if(thum < 0 ){
			   this.setState({thumbDefault : 0})
			 }else{
				this.setState({thumbDefault : thum});
			}    
		}
	}
	
	
	 getImagesFrontUpload(){
		 /*====Thumb image while choosing from front end===*/
		  if(Object.keys(this.fileArray).length > 0) {
			var thumbDefaultArr = this.state.thumbDefault;
		 
			 const imgThumblist = this.fileArray.map((url, index) => {
						var indicheckFlag = false;
												
						//if(Object.keys(thumbDefaultArr).length > 0){
							if(thumbDefaultArr != null && thumbDefaultArr == index){
								indicheckFlag = true;
							}
						//}
						  return (
							<li className="thumb" key={index}  >
								<input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} />
								<span><img src={url}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}
	 }

  render() {
  	let preview = '';
	

  	const {selectedOption, cms_image_preview} = this.state;

	if (cms_image_preview!== null && cms_image_preview!== '') {
	preview = <img className="img_class" src={cms_image_preview} alt="" />;
	}

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="landing_cms" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div className="title">
				<h4>Add CMS</h4>
			</div>
		    <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Name:</label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
					<div className="errorname"></div>
				</div>
				
				<div className="form-group">					
  					<label>Logo Content:</label>
 						{/*<textarea type="text" name="logo_content" onChange={this.handleInputChange} className="form-control" value={this.state.logo_content} />*/}
 				     <CKEditor
					data={this.state.logo_content}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
  					<div className="errorlogo_content"></div>
  				</div>

			</div>				
			<div className="form-right">
				
				 <div className="form-group">
					<label>Logo Image:</label>
				<div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn">
				    <input type="file" className="form-control" name="file" onChange={this.onChangeHandler}  />
					</span>
                </div>
				
				<div className="form-group">
				 <ul className="carimagelisting">
						{/*imageslist*/}
						{/*imgThumblist*/}
							{this.getImagesFrontUpload()}
						
					</ul>
					
				</div>
				</div>

				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	cmsadd     : state.cmsadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddCms: (formPayload) =>{
       dispatch({ type: GET_ADDCMS, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));