import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CKEditor from 'ckeditor4-react';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";

	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			flag_name:'',
			title:'',
			description:'',
			content:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			image_name: [],
			image_preview: [],
			image:[],
			icon_preview : '',
			image_fileExtension:[],
			allImgArray : [],
			apply_on_maintab : false,
			apply_on_innertab : false,
			content_type : '',
			flag_color:'',
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		if(name === 'apply_on_maintab'){
			this.setState({apply_on_maintab: !this.state.apply_on_maintab});
		} 
		if(name === 'apply_on_innertab'){
			  this.setState({apply_on_innertab: !this.state.apply_on_innertab});
		  } 	

  }

  validateForm() {
	//const {admin_company_name,admin_fname} = this.state;
	const {flag_name , apply_on_maintab , apply_on_innertab , button_text , content_type}  = this.state;
	var description = localStorage.getItem("tab_content");
	let errors = {};
	  let formIsValid = true;
	
	if (!flag_name) {
		formIsValid = false;
		$('.errorflag_name').html('<span class="errorspan">Please fill the field</span>');
	}else if(flag_name){
		$('.errorflag_name').html('');
	}
	
	

	


	

	
	
	

	this.setState({
		errors: errors
	});
	return formIsValid;
}

  handleSubmit = () => {
	if(this.validateForm()){ 
	const formdata = this.state;
	var qs = require('qs');
	var status = '';

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		flag_name : formdata.flag_name,
		flags_color : formdata.flag_color,
		status: status,
		
	}
	

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}

	

	axios.post(apiUrl+"tabmanagement/addflag",formData).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/flags'));
						}
						.bind(this),
						3000
						);
					
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
			}
		});
	}
		
	}

	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			icon_name: file.name,
			icon_preview: reader.result,
			icon: file,
			icon_fileExtension:extension,
			
		  });
		};
		reader.readAsDataURL(file);
		
	  } 


	  onChangeMultipleHandler= e => {
		this.fileObj = [];
		this.fileNameArray = this.state.image_name;
		this.fileArray = this.state.image;
		this.extensionArray = this.state.image_fileExtension;
		this.image_preview = this.state.image_preview;
		this.allImgArray = this.state.allImgArray;
		this.fileObj.push(e.target.files);
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileNameArray.push(this.fileObj[0][i].name)
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			this.allImgArray.push(this.fileObj[0][i])
			this.extensionArray.push(this.fileObj[0][i].name.split('.').pop());
			
		}
		
		this.setState({
			image_name: this.fileNameArray,
			image_preview: this.image_preview,
			image: this.fileArray,
			image_fileExtension:this.extensionArray,
			
		  });
		
	  }
	  
	removeImagebyindex = (indexvalue) => {
		var list = this.state.image;
		var allImgArray = this.state.allImgArray;
		
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
		allImgArray.splice(indexvalue,1)

	
		this.setState({ image: list , allImgArray : allImgArray })
		
	}

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData())
    	// var callfunc = this;
    	// setTimeout(function () {
		// callfunc.setState( {
        //     terms_and_condition: evt.editor.getData()
        // } );
		// }, 1000);
		localStorage.setItem('tab_content',evt.editor.getData())
     
    }

  render() {

	const {selectedOption} = this.state;
	var imgThumblist ;
	let indexlength =  this.state.image.length;
	if(this.state.image.length > 0){
		
		imgThumblist =  this.state.image.map((url,index) => (
				<li className="thumb" key={indexlength+index}  >
					<span><img src={url}  alt="..." /></span>
					<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
				</li>
			));
		}
  

	var	preview = (this.state.icon_preview) ? 
	<img className="img_class" src={this.state.icon_preview} alt="" /> :'';

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="flags" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Tab</h4>	
				<a className="" href='/flags'><span class="close">X</span></a>	
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Flag Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="flag_name" onChange={this.handleInputChange} className="form-control" value={this.state.flag_name}/>
					<div className="errorflag_name"></div>
				</div>

				

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
	           </div>
			</div>
                  
			<div className="form-right">

				
						<div className="form-group">
							<label>Flag color:</label><br></br>
							<input type="color" id="head" name="flag_color" className="form-control"  value={this.state.flag_color} onChange={this.handleInputChange} />
							<div className="error_flag_color"></div>
						</div>
				

				

			   
				
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));