import { SET_CMSDETAIL } from '../actions';

const cmsdetail = (state = [], action) => {
  switch (action.type) {
	case SET_CMSDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export default cmsdetail;
