/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { cmscontentURL} from'../Config/Config';

import { GET_UPDATEPLUGINCONTENT } from '../../actions';

import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";

import { apiUrl} from'../Config/Config';

class Cmscontent extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			id:'',
			Loading: false,
			loader_image: [],
			loader_image_name: "",
			loader_image_preview: "",
			loader_name:'',
			payment_page_content:'',
			signup_success_message:'',
		};
		
		var admin_id =  localStorage.getItem("admin_id");

		var admindetails = apiUrl+'adminpanel/getplugincontent?adminId='+admin_id;
		axios.get(admindetails)
	   .then(res => {
		   if(res.data.status === "success"){
			const formpayload = res.data.results;
			this.setState({id:formpayload.id});
			this.setState({loader_image_preview: formpayload.loader_image});
			this.setState({loader_name: formpayload.loader_text});
			this.setState({payment_page_content: formpayload.payment_success_message});
			this.setState({signup_success_message: formpayload.signup_success_message});
		   }else{
			this.setState({loader_image_preview: ''});
			this.setState({loader_name: ''});
			this.setState({payment_page_content: ''});
			this.setState({signup_success_message: ''});
		   }
	    });

		this.handleInputChange  = this.handleInputChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		
    }

    handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
   
      if(name === 'settings_enable_live'){
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      	  this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      }	
      if(name === 'settings_enable_sandbox'){
      	 this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      }	


	  if(name === 'category_icons'){
		this.setState({category_icons_yes: !this.state.category_icons_yes});
		this.setState({category_icons_no: !this.state.category_icons_no});
     }
    }
	
    componentDidMount() {
      document.title = PageTitle('Plugin Contents');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
     }
	 
   onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	  this.setState({
		loader_image_name: file.name,
		loader_image_preview: reader.result,
		loader_image: file
	  });
	};
	reader.readAsDataURL(file);
  }

    handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				  
				const formPayload = this.state;

				var qs = require('qs');
			
				var postObject = {
					id: formPayload.id,
					admin_id: localStorage.getItem('admin_id'),
					loader_image: formPayload.loader_image,
					loader_text: formPayload.loader_name,
					payment_page_content: formPayload.payment_page_content,
					signup_success_message: formPayload.signup_success_message
				};

			
	
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}

				axios.post(apiUrl+"adminpanel/saveplugincontent",formData,config).then(res => {
					if(res.data.results){
						this.setState({ Loading: false });

    			const formpayload = res.data.results;
				
    			$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
    			this.setState({id:formpayload.id});
				this.setState({loader_image_preview: formpayload.loader_image});
				this.setState({payment_page_content: formpayload.payment_success_message});
				this.setState({signup_success_message: formpayload.signup_success_message});
				this.setState({loader_name: formpayload.loader_text});

		
    				scrollToTop();

    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {

		const {} = this.state;
		let errors = 0;
      	//let formIsValid = true;
	
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

    componentWillReceiveProps(Props){

    	  	if(Props.updateplugincontent !== this.props.updateplugincontent){ 
			
    		if(Object.keys(Props.updateplugincontent).length > 0){
				
    			this.setState({ Loading: false });

    			const formpayload = Props.updateplugincontent[0].results;
				
    			$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updateplugincontent[0].message+'</h3></div>');
    			this.setState({id:formpayload.id});
				this.setState({loader_image_preview: formpayload.loader_image});
				this.setState({payment_page_content: formpayload.payment_success_message});
				this.setState({signup_success_message: formpayload.signup_success_message});
				this.setState({loader_name: formpayload.loader_text});

		
    				scrollToTop();

    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}
    	}
		

	}

  
	removeImage  = () => {
		this.setState({loader_image_preview:''});
	}

  render() {
		
	let { loader_image_preview} = this.state;
	let loaderimg = '';
	let preview = '';

	if (this.state.id!=='' && loader_image_preview!== null && loader_image_preview!== '') {
	    loaderimg = cmscontentURL + "/" + loader_image_preview;
		preview = <img className="img_class" src={loaderimg} alt="" />;
	}else{
		preview = <img className="img_class" src={loader_image_preview} alt="" />;
	}
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar currentpage="plugincontent" />		

	
	<div className="content">	
		<div className="content-wrapper">
		        <div class="content-wrapper-before"></div>
			<div className="content-body leaa-admin-head card">
			<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Plugin Contents</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
			
				

				<div className="form-group">					
    				  <label>Signup Success Message</label>
   						<textarea type="text" name="signup_success_message" onChange={this.handleInputChange} className="form-control" value={this.state.signup_success_message} />
    		 	</div>

				 <div className="form-group">					
    				  <label>Loader Text</label>
   						<input type="text" name="loader_name" onChange={this.handleInputChange} className="form-control" value={this.state.loader_name} />
    		 	</div>

			</div>
			<div className="form-right">

			
				<div className="form-group">					
    				  <label>Payment page success Message</label>
   						<textarea type="text" name="payment_page_content" onChange={this.handleInputChange} className="form-control" value={this.state.payment_page_content} />
    		 	</div>

				   <div className="form-group">
						<label>Loader Image</label>
						<div className="choose-file">
					     {this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					    </div>
						{preview}
						{/* {loader_image_preview?<a href="javascript:void(0);" onClick={this.removeImage}>X</a>:''} */}
						<div className="errorloaderimage"></div> 
					</div>
			

				  
			</div>				
			</div>	
	
       	<div className="form-row">
			
         	</div>		
			<div className="btn-group export">	
				
                <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                <span className="load-data">Loading</span> 
                                } Submit
			    </button>	
			</div>

			</div>
			</form>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default (withRouter(Cmscontent));